import MomentUtils from '@date-io/moment';
import { FormControl } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import loadImage from 'blueimp-load-image';
import moment from 'moment';
import { useEffect, useRef } from 'react';

import AdvancedUnitsToggleSelectors from './advancedUnitsToggleSelectors';
import styles from './Profile.module.scss';
import Row from './Row';

import Button from '../../../components/button';
import Spinner from '../../../components/spinner';
import TranslateOption from '../../../components/translateOption';
import Translate, { useTranslation } from '../../../components/translate';
import {
    LANGUAGES,
    makeCancellable,
    RequestType,
    SRAM_RED,
    toLocaleString,
    URL_STATIC_DATA,
} from '../../../constants';
import { useSetState } from '../../../hooks';
import { useAuth, useNexus, useUnits } from '../../../providers';
import { UNIT_SYSTEMS, UNITS, UNITS_IMPERIAL } from '../../../providers/units/constants';
import { useQatalystActivitySummaries } from '../../../providers/qatalyst';
import CropImageModal from '../../../views/cropImageModal';
import ErrorModal from '../../../views/errorModal';

const materialTheme = createTheme({ palette: { primary: { main: SRAM_RED } } });

export const MIN_AGE = moment()
    .utc()
    .subtract(16, 'years')
    .endOf('year')
    .toDate();

function formatWeight(weight: number, unit: string) {
    return (unit === UNITS.kg) ? Math.round(weight * 2) / 2 : Math.round(weight);
}

function Profile() {
    const auth = useAuth();
    const nexus = useNexus();
    const units = useUnits();
    const translate = useTranslation();
    const { qatalystUserActivitySummaries }: any = useQatalystActivitySummaries(auth);
    const [state, setState] = useSetState({
        croppedImage: null,
        error: null,
        imageCanvas: null,
        isFetching: false,
        profileToUpdate: nexus.nexusUserProfile,
        showCropImageModal: false,
    });
    const {
        croppedImage,
        error,
        imageCanvas,
        isFetching,
        profileToUpdate,
        showCropImageModal,
    } = state;
    const { nexusUserProfile } = nexus;

    const updateNexusUserProfileRequests = useRef(new Set<RequestType>());

    const convertInputs = (oldProfileToUpdate: any, newProfileToUpdate: any) => {
        const newProfileUpdate = newProfileToUpdate;

        // if the user profile did not have a unit system selected, don't do any conversions.
        if (!oldProfileToUpdate.units) return newProfileToUpdate;

        let newHeight = 0;
        let newWeight = 0;

        const oldHeightUnit = units.getUnit('height', null, oldProfileToUpdate);
        const newHeightUnit = units.getUnit('height', null, newProfileUpdate);

        if (oldHeightUnit !== newHeightUnit) {
            newHeight = units.convertHeightToSI(newProfileUpdate.height, oldHeightUnit);
            newHeight = units.convertHeightFromSI(newHeight, newHeightUnit);
            newHeight = units.formatHeight(newHeight, newHeightUnit);
            newProfileUpdate.height = newHeight;
        }

        const oldWeightUnit = units.getUnit('mass', null, oldProfileToUpdate);
        const newWeightUnit = units.getUnit('mass', null, newProfileUpdate);

        if (oldWeightUnit !== newWeightUnit) {
            newWeight = units.convertWeightToSI(newProfileUpdate.weight, oldWeightUnit);
            newWeight = units.convertWeightFromSI(newWeight, newWeightUnit);
            newWeight = formatWeight(newWeight, newWeightUnit);
            newProfileUpdate.weight = newWeight;
        }

        return newProfileUpdate;
    };

    const convertHeight = (toSI?: boolean) => {
        const heightUnit = units.getUnit('height', null, profileToUpdate);

        if (toSI) return units.convertHeightToSI(profileToUpdate.height, heightUnit);

        return units.formatHeight(units.convertHeightFromSI(profileToUpdate.height, heightUnit), heightUnit);
    };

    const convertWeight = (toSI?: boolean) => {
        const weightUnit = units.getUnit('mass', null, profileToUpdate);

        if (toSI) return units.convertWeightToSI(profileToUpdate.weight, weightUnit);

        return formatWeight(units.convertWeightFromSI(profileToUpdate.weight, weightUnit), weightUnit);
    };

    const updateProfile = (key: string, value: any, convertUnits?: boolean) => {
        // create new profile object with key, value update
        let newProfileToUpdate = {
            ...profileToUpdate,
            [key]: value,
        };

        if (convertUnits) {
            // convert user inputs to reflect unit changes
            newProfileToUpdate = convertInputs(profileToUpdate, newProfileToUpdate);
        }

        setState({ profileToUpdate: newProfileToUpdate });
    };

    const onImageCrop = (croppedCanvas: any) => {
        croppedCanvas.toBlob(
            (blob: Blob) => {
                const imageFile = new File([blob], 'croppedProfileImage.jpg');
                updateProfile('picture', imageFile);
            },
            'image/jpeg',
        );

        setState({
            croppedImage: croppedCanvas.toDataURL('image/jpg'),
            imageCanvas: null,
            showCropImageModal: !showCropImageModal,
        });
    };

    const onImageSelect = (newImage: any) => {
        if (!newImage) return;

        loadImage(
            newImage,
            (canvas) => setState({ imageCanvas: canvas, showCropImageModal: true }),
            { canvas: true, orientation: true },
        );
    };

    const getBirthDate = () => {
        const { profileToUpdate: { birthdate } } = state;

        return birthdate && moment(birthdate).toDate();
    };

    const getUpdatesToSave = () => {
        const profileToSubmit: any = {};

        Object.keys(profileToUpdate).forEach((key) => {
            if (
                profileToUpdate[key] !== null
                && profileToUpdate[key] !== undefined
                && key !== 'picture'
                && typeof profileToUpdate[key] === 'object'
            ) {
                const newValue: any = {};

                Object.keys(profileToUpdate[key]).forEach((nestedKey) => {
                    if (profileToUpdate[key][nestedKey] !== nexusUserProfile[key][nestedKey]) {
                        newValue[nestedKey] = profileToUpdate[key][nestedKey];
                        profileToSubmit[key] = newValue;
                    }
                });

                return;
            }

            if (key === 'weight') {
                const convertedWeight = convertWeight(true);

                if (convertedWeight !== nexusUserProfile[key]) {
                    profileToSubmit.weight = convertedWeight;
                }

                return;
            }

            if (key === 'height') {
                const convertedHeight = convertHeight(true);

                if (convertedHeight !== nexusUserProfile[key]) {
                    profileToSubmit.height = convertedHeight;
                }

                return;
            }

            if (profileToUpdate[key] !== nexusUserProfile[key]) {
                profileToSubmit[key] = profileToUpdate[key];
            }
        });

        return profileToSubmit;
    };

    const submitProfileUpdate = async (event: any) => {
        event.preventDefault();

        setState({ isFetching: true });

        const profileToSubmit = getUpdatesToSave();
        const fetchRequest = makeCancellable(nexus.updateNexusUserProfile(profileToSubmit));
        updateNexusUserProfileRequests.current.add(fetchRequest);

        try {
            const newProfile = await fetchRequest.promise;
            updateNexusUserProfileRequests.current.delete(fetchRequest);

            if (!newProfile) {
                setState({
                    error: new Error('PROFILE_NEW_ERROR'),
                    isFetching: !!updateNexusUserProfileRequests.current.size,
                });

                return;
            }

            setState({
                isFetching: !!updateNexusUserProfileRequests.current.size,
                profileToUpdate: newProfile,
            });
        } catch (err: any) {
            if (!err.isCancelled) {
                updateNexusUserProfileRequests.current.delete(fetchRequest);
                setState({
                    error: new Error('PROFILE_NEW_ERROR'),
                    isFetching: !!updateNexusUserProfileRequests.current.size,
                });
            }
        }
    };

    const updateProfileAdvancedUnits = (key: string, value: any) => {
        // Create new profile object with key, value update
        let newProfileToUpdate = { ...profileToUpdate };
        newProfileToUpdate.advanced_units = {
            ...profileToUpdate.advanced_units,
            [key]: value,
        };

        // Convert user inputs to reflect unit changes
        newProfileToUpdate = convertInputs(profileToUpdate, newProfileToUpdate);
        setState({ profileToUpdate: newProfileToUpdate });
    };

    const isWeightImperial = () => (units.getUnit('mass', null, profileToUpdate) === UNITS_IMPERIAL.mass);

    const renderAdvancedUnits = () => {
        if (profileToUpdate.units !== UNIT_SYSTEMS.ADVANCED) return null;

        return (
            <AdvancedUnitsToggleSelectors
                advancedUnits={profileToUpdate.advanced_units}
                onChange={(key, value) => updateProfileAdvancedUnits(key, value)}
            />
        );
    };

    const renderCropImageModal = () => (
        <CropImageModal
            aspectRatio={1}
            imageCanvas={imageCanvas}
            onCancel={() => setState({ imageCanvas: null, showCropImageModal: !showCropImageModal })}
            onCrop={(croppedCanvas) => onImageCrop(croppedCanvas)}
            open={showCropImageModal}
        />
    );

    const renderInputs = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const userLocale = navigator.language || navigator.userLanguage;
        const localeDateFormat = userLocale ? (moment.localeData(userLocale).longDateFormat('L')) : 'DD/MM/YYYY';

        return (
            <div style={{ paddingTop: '1rem' }}>
                <Row>
                    <div className={styles.profileImageContainer}>
                        <img
                            alt=""
                            className={styles.profileImage}
                            src={croppedImage || (nexusUserProfile.picture && (
                                nexusUserProfile.picture.replace('https://static.quarqnet.com/', URL_STATIC_DATA)
                            ))}
                        />
                        <div className={styles.imagePickerLogoContainer}>
                            <input
                                className={styles.imagePickerLogo}
                                onChange={(event: any) => {
                                    onImageSelect(event.target.files[0]);
                                    // eslint-disable-next-line no-param-reassign
                                    event.target.value = '';
                                }}
                                type="file"
                            />
                            <AddAPhotoIcon />
                        </div>
                    </div>
                </Row>
                <Row label="NICK_NAME">
                    <input
                        className={styles.inputField}
                        id="name"
                        onChange={(event) => updateProfile('nickname', event.target.value)}
                        placeholder={translate('ENTER_NAME')}
                        required
                        type="text"
                        value={profileToUpdate.nickname}
                    />
                </Row>
                <Row label="FIRST_NAME">
                    <input
                        className={styles.inputField}
                        id="fname"
                        onChange={(event) => updateProfile('first_name', event.target.value)}
                        placeholder={translate('ENTER_FIRST_NAME')}
                        type="text"
                        value={profileToUpdate.first_name || ''}
                    />
                </Row>
                <Row label="LAST_NAME">
                    <input
                        className={styles.inputField}
                        id="lname"
                        onChange={(event) => updateProfile('last_name', event.target.value)}
                        placeholder={translate('ENTER_LAST_NAME')}
                        type="text"
                        value={profileToUpdate.last_name || ''}
                    />
                </Row>
                <Row label="PROFILE_WEIGHT">
                    <input
                        className={styles.inputField}
                        id="weight"
                        max={1500}
                        min={1}
                        onChange={(event) => updateProfile('weight', event.target.value)}
                        placeholder="---"
                        required
                        step={isWeightImperial() ? 1 : 0.5}
                        type="number"
                        value={profileToUpdate.weight}
                    />
                    <div className={styles.units}>
                        <Translate>
                            {units.getLabelWeight(units.getUnit('mass', null, profileToUpdate)).shorthand}
                        </Translate>
                    </div>
                </Row>
                <Row label="PROFILE_HEIGHT">
                    <input
                        className={styles.inputField}
                        min={0}
                        onChange={(event) => updateProfile('height', event.target.value)}
                        placeholder="--"
                        required
                        type="number"
                        value={profileToUpdate.height
                            ? Math.round(profileToUpdate.height)
                            : profileToUpdate.height}
                    />
                    <div className={styles.units}>
                        <Translate>
                            {units.getLabelHeight(units.getUnit('height', null, profileToUpdate)).shorthand}
                        </Translate>
                    </div>
                </Row>
                <Row label="PROFILE_BIRTHDATE">
                    <div className={styles.datePickerOverride}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={userLocale}>
                            <ThemeProvider theme={materialTheme}>
                                <FormControl classes={{ root: styles.themeProviderRoot }}>
                                    <KeyboardDatePicker
                                        InputProps={{
                                            classes: { input: styles.datePickerInput, root: styles.datePickerRoot },
                                            disableUnderline: true,
                                        }}
                                        disableFuture
                                        emptyLabel={localeDateFormat}
                                        format={localeDateFormat}
                                        maxDate={MIN_AGE}
                                        onChange={(date) => updateProfile(
                                            'birthdate',
                                            date ? moment(date).format('YYYY-MM-DD') : null,
                                        )}
                                        onOpen={() => {
                                            /** if no birth date is available, set default to
                                            * minimum age birthdate allowed.
                                            */
                                            if (!profileToUpdate.birthdate) {
                                                updateProfile('birthdate', MIN_AGE);
                                            }
                                        }}
                                        value={getBirthDate()}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </div>
                </Row>
                <Row label="PROFILE_AGE">
                    <div className={styles.displayStats}>
                        {nexusUserProfile.age}
                    </div>
                </Row>
                <Row label="TOTAL_ACTIVITIES">
                    <div className={styles.displayStats}>
                        {qatalystUserActivitySummaries?.total_activities}
                    </div>
                </Row>
                <Row label="TOTAL_DISTANCE">
                    <div className={styles.displayStats}>
                        {toLocaleString(units.formatDistance(units.convertDistanceFromSI(
                            qatalystUserActivitySummaries?.total_distance,
                        )))}
                            &nbsp;
                        <Translate>{units.getLabelDistance().shorthand}</Translate>
                    </div>
                </Row>
                <Row label="TOTAL_DURATION">
                    <div className={styles.displayStats}>
                        {moment
                            .duration(qatalystUserActivitySummaries?.total_duration, 'seconds')
                            .format('h')}
                        &nbsp;
                        <Translate>HRS</Translate>
                    </div>
                </Row>
                <Row label="PROFILE_FTP">
                    <input
                        className={styles.inputField}
                        id="ftp"
                        min={1}
                        onChange={(event) => updateProfile('ftp', event.target.value)}
                        placeholder="---"
                        type="number"
                        value={profileToUpdate.ftp}
                    />
                    <div className={styles.units}>
                        <Translate>UNITS_WATTS</Translate>
                    </div>
                </Row>
                <Row label="LTHR">
                    <input
                        className={styles.inputField}
                        id="lthr"
                        min={1}
                        onChange={(event) => updateProfile('lactate_threshold', event.target.value)}
                        placeholder="---"
                        type="number"
                        value={profileToUpdate.lactate_threshold}
                    />
                    <div className={styles.units}>
                        <Translate>UNITS_BPM</Translate>
                    </div>
                </Row>
            </div>
        );
    };

    const renderLanguageSelect = () => (
        <select
            className={styles.inputField}
            onChange={(event) => updateProfile('language_code', event.target.value)}
            value={profileToUpdate.language_code}
        >
            {(LANGUAGES.sort((a: any, b: any) => ((a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0))
                .map(({ label, locale }: { label: string, locale: string }) => <option value={locale} key={locale}>{label}</option>)
            )}
        </select>
    );

    useEffect(() => () => {
        updateNexusUserProfileRequests.current.forEach((request) => request.cancel());
    }, []);

    useEffect(() => {
        if (!isFetching) {
            setState({
                profileToUpdate: {
                    ...profileToUpdate,
                    height: convertHeight(),
                    weight: convertWeight(),
                },
            });
        }
    }, [isFetching]);

    return (
        <div>
            <Spinner loading={isFetching} />
            <div>
                <div className={styles.header}>
                    <Translate>PROFILE_TITLE</Translate>
                </div>
                <div className={styles.subTitle}>
                    {nexusUserProfile.email}
                </div>
            </div>
            <form onSubmit={(event) => submitProfileUpdate(event)}>
                {renderInputs()}
                <div className={styles.header} style={{ marginTop: '1.5rem' }}>
                    <Translate>PROFILE_DISPLAY_PREFERENCES</Translate>
                </div>
                <Row label="PROFILE_TIME_FORMAT">
                    <select
                        className={styles.inputField}
                        id="time"
                        onChange={(event) => updateProfileAdvancedUnits('time_format', event.target.value)}
                        value={profileToUpdate.advanced_units.time_format}
                    >
                        <TranslateOption value={UNITS.hour_12}>
                            PROFILE_TIME_12
                        </TranslateOption>
                        <TranslateOption value={UNITS.hour_24}>
                            PROFILE_TIME_24
                        </TranslateOption>
                    </select>
                </Row>
                <Row label="LANGUAGE">
                    {renderLanguageSelect()}
                </Row>
                <Row label="UNITS">
                    <select
                        className={styles.inputField}
                        id="unit"
                        onChange={(event) => updateProfile('units', event.target.value, true)}
                        value={profileToUpdate.units}
                    >
                        <TranslateOption value={UNIT_SYSTEMS.IMPERIAL}>
                            UNITS_IMPERIAL
                        </TranslateOption>
                        <TranslateOption value={UNIT_SYSTEMS.METRIC}>
                            UNITS_METRIC
                        </TranslateOption>
                        <TranslateOption value={UNIT_SYSTEMS.ADVANCED}>
                            CUSTOM
                        </TranslateOption>
                    </select>
                </Row>
                {renderAdvancedUnits()}
                <Row>
                    <Button type="submit">
                        <Translate>SAVE</Translate>
                    </Button>
                </Row>
            </form>
            <ErrorModal
                error={error}
                onClose={() => setState({ error: null })}
                onOverlayClick={() => setState({ error: null })}
            />
            {renderCropImageModal()}
        </div>
    );
}

export default Profile;
