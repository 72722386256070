import MaterialCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import styles from './Checkbox.module.scss';

const StyledCheckbox = withStyles({
    checked: {},
    root: {
        '&$checked': {
            color: '#E51937',
        },
    },
})(MaterialCheckbox);

interface CheckboxProps {
    showBorder?: boolean;
    value?: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ showBorder, value, ...otherProps }: CheckboxProps) => (
    <div className={`${styles.container} ${showBorder ? styles.border : null}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StyledCheckbox {...otherProps} />
        <div className={styles.selection}>
            {value}
        </div>
    </div>
);

export default Checkbox;
