import { withStyles } from '@material-ui/core/styles';
import MaterialSlider from '@material-ui/core/Slider';
import ValueLabel from '@material-ui/core/Slider/ValueLabel';

import { SRAM_600, SRAM_RED } from '../../constants';

const StyledValueLabel = withStyles({
    circle: {
        borderRadius: '0',
        height: '18px',
        transform: 'initial',
        width: '45px',
    },
    label: {
        transform: 'initial',
    },
    offset: {
        top: '-12px',
    },
})(ValueLabel);

// withStyles is a recommended approach to apply styles to materia ui components
const DefaultSlider = withStyles({
    rail: {
        borderRadius: 4,
        color: SRAM_600,
        height: 4,
    },
    root: {
        color: SRAM_RED,
    },
    thumb: {
        border: '2px solid currentColor',
        color: SRAM_RED,
        height: 24,
        marginLeft: -12,
        marginTop: -10,
        width: 24,
        // eslint-disable-next-line sort-keys
        '&:hover': {
            boxShadow: '0px 0px 0px 8px rgba(229, 25, 55, 0.16)',
        },
    },
    track: {
        borderRadius: 4,
        height: 4,
    },
})(MaterialSlider);

const Slider = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DefaultSlider {...props} ValueLabelComponent={StyledValueLabel} />
);

export default Slider;
