export * from './serverDependant';
export * from './colours';

const GET_CHAINSTAY_LENGTH = () => {
    const chainstayLength = [];
    for (let i = 425; i < 471; i++) {
        chainstayLength.push({
            label: `${i} mm`,
            value: i,
        });
    }
    return chainstayLength;
};

const GET_DROP_BAR_CHAINSTAY_LENGTH = () => {
    const chainstayLength = [];
    for (let i = 415; i < 451; i++) {
        chainstayLength.push({
            label: `${i} mm`,
            value: i,
        });
    }
    return chainstayLength;
};

export const ACTIVITY_TYPES = [
    { label: 'ACTIVITY_TYPE_RIDE', value: 'ride' },
    { label: 'ACTIVITY_TYPE_RUN', value: 'run' },
    { label: 'ACTIVITY_TYPE_WALK', value: 'walk' },
];

export const CHAIN_CALULATOR_REF_MODELS = [1058, 1059, 1060, 1072, 1073, 1075];

export const CHAINRING_SIZES = [
    { label: '30T', value: 30 },
    { label: '32T', value: 32 },
    { label: '34T', value: 34 },
    { label: '36T', value: 36 },
    { label: '38T', value: 38 },
];
export const GET_DROP_BAR_CHAINRING_SIZES = () => {
    const chainRingSizes = [];
    for (let i = 36; i < 55; i += 2) {
        chainRingSizes.push({
            label: `${i}T`,
            value: i,
        });
    }
    return chainRingSizes;
};

export const CHAINSTAY_LENGTH = GET_CHAINSTAY_LENGTH();
export const DROP_BAR_CHAINSTAY_LENGTH = GET_DROP_BAR_CHAINSTAY_LENGTH();
export const DROP_BAR_CHAINRING_SIZES = GET_DROP_BAR_CHAINRING_SIZES();

export const COPYRIGHT_YEAR = new Date().getFullYear();

export const DEEP_LINK_ARMY_KNIFE_IOS = 'com.sram.armyknife://';
export const DEEP_LINK_ARMY_KNIFE_ANDROID = 'com.sram.armyknife://sramid-auth.sram.com/android/com.sram.armyknife/callback';
export const DEEP_LINK_SHOCKWIZ = 'com.sram.shockwiz://axs.sram.com/emailverify';

export const DEEP_LINK_USERSETTINGS = 'com.sram.usersettings:///usersettings/callback';

export const DEVICE_TYPES = {
    cadence: 122,
    gears: 34,
    gps: 256,
    heartRate: 120,
    power: 11,
    reverb: 115,
    shockWiz: 25,
    speed: 123,
    speedCadence: 121,
    tirePressure: 48,
};

export const XPLR_SUPPORT_VIDEOS = [
    {
        name: 'XPLR_INSTALLATION',
        url: 'https://www.youtube.com/watch?v=i3jtyDbXhEI',
    },
];

export const EAGLE_TRANSMISSION_SUPPORT_VIDEOS = [
    {
        name: 'PREPARE_CHAIN_SETUP_COG',
        url: 'https://www.youtube.com/watch?v=MNBHCI5gVhY&list=PLtfnVpdul4H0Dy491GpjRYG7nS2ed9TUc',
    },
    {
        name: 'SHIFTING_SETUP_COG',
        url: 'https://www.youtube.com/watch?v=agBEhI_HOos&list=PLtfnVpdul4H0Dy491GpjRYG7nS2ed9TUc',
    },
    {
        name: 'CHAIN_INSTALLATION',
        url: 'https://www.youtube.com/watch?v=DHogJrgOnK4&list=PLtfnVpdul4H0Dy491GpjRYG7nS2ed9TUc',
    },
    {
        name: 'SETUP_CONTROLLERS',
        url: 'https://www.youtube.com/watch?v=hzFSbf-ZVIE&list=PLtfnVpdul4H0Dy491GpjRYG7nS2ed9TUc',
    },
    {
        name: 'PAIRING_CONTROLLERS',
        url: 'https://www.youtube.com/watch?v=jXHPCRaAue4&list=PLtfnVpdul4H0Dy491GpjRYG7nS2ed9TUc',
    },
];

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyByL9uX1sE-HQHGCi-LhKzeEyrSgl-qVGY',
    appId: '1:453743219339:web:1dafcc85f658dac12dfa8e',
    authDomain: 'axs-web-f95e4.firebaseapp.com',
    measurementId: 'G-FB8JERB818',
    messagingSenderId: '453743219339',
    projectId: 'axs-web-f95e4',
    storageBucket: 'axs-web-f95e4.appspot.com',
};

export const GOOGLE_MAPS_KEY = 'AIzaSyBPbzj0ETecun50lOw_xDS3_VnqFvZAXQs';
export const GOOGLE_MAPS_LIBRARIES = ['geometry', 'drawing', 'places'];

export const GRID_BREAKPOINTS = {
    lg: 992,
    md: 768,
    sm: 576,
    xl: 1200,
    xs: 350,
};

// Tire Size & ISO Tire Pressure Table (hookless only)
export const ISO_TIRE_PRESSURE_RANGE = [
    { maxTireWidth: 24, minTireWidth: 18, pressureThreshold: 5500 },
    { maxTireWidth: 29, minTireWidth: 25, pressureThreshold: 5000 },
    { maxTireWidth: 34, minTireWidth: 30, pressureThreshold: 4500 },
    { maxTireWidth: 39, minTireWidth: 35, pressureThreshold: 4000 },
    { maxTireWidth: 44, minTireWidth: 40, pressureThreshold: 3500 },
    { maxTireWidth: 54, minTireWidth: 45, pressureThreshold: 3000 },
    { maxTireWidth: 64, minTireWidth: 55, pressureThreshold: 2500 },
    { maxTireWidth: 74, minTireWidth: 65, pressureThreshold: 2000 },
    { maxTireWidth: 84, minTireWidth: 75, pressureThreshold: 1500 },
];

export const LANGUAGES = [
    { label: 'English', locale: 'en' },
    { label: 'Deutsch', locale: 'de' },
    { label: 'Español', locale: 'es-us' },
    { label: 'Français', locale: 'fr' },
    { label: 'Italiano', locale: 'it' },
    { label: '日本語 (にほんご) (Japanese)', locale: 'ja' },
    { label: '한국어 (Korean)', locale: 'ko' },
    { label: 'Nederlands', locale: 'nl' },
    { label: 'Português', locale: 'pt-pt' },
    { label: 'Thai', locale: 'th' },
    { label: '中文(简体) (Chinese Simplified)', locale: 'zh-cn' },
    { label: '中文(台灣) (Chinese Traditional)', locale: 'zh-tw' },
];

export const MOMENT_TWELVE_HOUR_FORMAT = 'LT';
export const MOMENT_TWENTY_FOUR_HOUR_FORMAT = 'H:mm';

export const REACT_APP_APPLANGA_API = 'https://api.applanga.com/v1/api';
export const REACT_APP_APPLANGA_APP = '6136d7463d71031d5d989a66';
export const REACT_APP_APPLANGA_TOKEN = '6136d7463d71031d5d989a66!58dc1e15f97db9f8412d71eb70e807b5';

export const SERVICE_APPLE = 'apple';
export const SERVICE_FACEBOOK = 'facebook';
export const SERVICE_GARMIN_CONNECT = 'garmin-connect';
export const SERVICE_GOOGLE = 'google-oauth2';
export const SERVICE_WAHOO = 'wahoo';

export const URL_MODELS = 'https://nexus.quarqnet.com/api/models/';

export const ZONE_COLORS_HEART = [
    '#F7D5E2',
    '#E9475F',
    '#E51937',
    '#AB1329',
    '#720D1C',
];
export const ZONE_COLORS_POWER = [
    '#C2C4C2',
    '#4285F4',
    '#34A853',
    '#FFCC3F',
    '#FF6639',
    '#E51937',
    '#A8132F',
];
