import {
    useEffect, useMemo, useRef, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Cookies } from 'react-cookie-consent';
import { FadeLoader } from 'react-spinners';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ContentContainer from '../../../components/contentContainer';
import styles from '../mobileChainLengthCalc.module.scss';
import Translate from '../../../components/translate';

import { checkCookieConsent } from '../../../components/analytics/Analytics';
import Logger from '../../../Logger';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import Spinner from '../../../components/spinner';
import { useAuth, useBikes } from '../../../providers';
import {
    CHAINRING_SIZES,
    makeCancellable, RequestType,
    SRAM_500,
    WHITE,
} from '../../../constants';
import SelectionModal from '../modals/SelectionModal';
import {
    bikeBrandType, bikeModelTypes, customValueType, frameTypes,
} from '../../../pages/fullMountChainLengthCalculator/MTBFlow/types';
import { useSetState } from '../../../hooks';
import DataUnavailableModal from '../modals/DataUnavailableModal';
import { BikeBrand } from '../types';
import SelectionModalAlt from '../modals/SelectionModalAlt';
import IdlerPullyNotice from '../modals/IdlerPullyNotice';

interface MTBFlowProps {
    setCustomBike: (value: boolean) => void,
    bikeBrandList: BikeBrand[],
    fetchBikeBrands: () => void,
    setSelectedBikeType: (value: string) => void,
    setOpenBikeTypeSelection: (value: boolean) => void,
    isBikeTypeSelectionOpen: boolean,
}

const DEFAULT_STATE = {
    categoryInfo: null,
    chainLength: null,
    chainRingOptions: CHAINRING_SIZES,
    chainStayLength: null,
    isCalculating: false,
    isFetching: false,
    selectedBikeBrand: null,
    selectedBikeFrame: null,
    selectedBikeModel: null,
    selectedBikeModelYear: null,
    selectedChainringSize: null,
    selectedFrameChainLengths: null,
    selectedFrameSize: null,
};

const MTBFlow = ({
    setCustomBike,
    bikeBrandList,
    fetchBikeBrands,
    setSelectedBikeType,
    setOpenBikeTypeSelection,
    isBikeTypeSelectionOpen,
}: MTBFlowProps) => {
    const bikes = useBikes();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const cookieConsent = Cookies.get('CookieConsent');
    const [currentQueryParameters, setSearchParams] = useSearchParams();
    const [state, setState] = useSetState({
        anchorEl: null,
        bikeModelList: null,
        linkagePosition: '',
        openChainLengthModal: false,
        openCustomBike: false,
        openDataUnavailableModal: false,
        openNoticeModal: false,
        openPopover: false,
        openSelectionModal: false,
        ...DEFAULT_STATE,
    });

    const {
        bikeModelList,
        chainLength,
        chainRingOptions,
        chainStayLength,
        isCalculating,
        isFetching,
        linkagePosition,
        openDataUnavailableModal,
        selectedBikeFrame,
        selectedBikeBrand,
        selectedBikeModel,
        selectedBikeModelYear,
        selectedChainringSize,
        selectedFrameChainLengths,
        selectedFrameSize,
        supportedChainRings,
        openSelectionModal,
    } = state;
    const [modalView, setModalView] = useState('');
    const useAltSelectionModal = modalView === 'FRAME_SIZE' || modalView === 'GEOMETRY';
    const fetchBikesByBrandRequest = useRef<RequestType | null>(null);
    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);
    const fetchUdhRequest = useRef<RequestType | null>(null);

    const disableCalcButton = !selectedBikeBrand
                    || !selectedBikeModel
                    || !selectedFrameSize
                    || !selectedChainringSize
                    || isCalculating;

    const clearParams = () => {
        const emptyParams : URLSearchParams = new URLSearchParams();
        setSearchParams(emptyParams);
    };

    const resetSelectedFields = () => {
        setState({
            linkagePosition: null,
            selectedBikeBrand: null,
            selectedBikeFrame: null,
            selectedBikeModel: null,
            selectedChainringSize: null,
            selectedFrameSize: null,
        });
    };

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            BikeModel: selectedBikeModel,
            Brand: selectedBikeBrand,
            ChainringSize: selectedChainringSize,
            FrameSize: selectedFrameSize,
        };

        const properties = {
            ...eventFields,
            action: 'Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Chainlength_Calculation', properties);
        }

        Logger.log('ChainLengthFormFields', eventFields);
    }

    function setSupportedChainRings() {
        if (!selectedFrameChainLengths || !selectedChainringSize) return;
        const selectedChainLength = selectedFrameChainLengths[selectedChainringSize.split('T')[0]];

        const supportedChainrings: Array<string> = [];
        Object.entries(selectedFrameChainLengths).forEach(([key, value]) => {
            if (value === selectedChainLength) {
                supportedChainrings.push(`${key}T`);
            }
        });

        setState({
            supportedChainRings: supportedChainrings,
        });
    }

    function setChainRingOptions(chainLengths: Record<string, unknown>) {
        const options = Object.keys(chainLengths).map((key) => ({ label: `${key}T`, value: key }));
        setState({ chainRingOptions: options });
    }

    function setChainInformation(frame: frameTypes) {
        if (!selectedFrameChainLengths || !selectedChainringSize) return;

        const selectedChainLength = selectedFrameChainLengths[selectedChainringSize.split('T')[0]];

        setState({
            chainLength: selectedChainLength,
            chainStayLength: frame.chainstay_length,
        });
    }

    async function fetchBikesByBrand(brandName: string) {
        const selectedBrand = bikeBrandList.find((brand: any) => brand.name === brandName);

        setState({ isFetching: true });
        if (!selectedBrand) return null;
        fetchBikesByBrandRequest.current = makeCancellable(bikes.fetchBikesByBrandId(selectedBrand.id));

        try {
            const data = await fetchBikesByBrandRequest.current.promise;

            if (data) setState({ bikeModelList: data });

            fetchBikesByBrandRequest.current = null;
            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikesByBrandRequest.current = null;
                Logger.error('fetchBikesByBrand', error);
            }

            return null;
        }
    }

    async function fetchChainLengths(frame: frameTypes) {
        if (!frame) return;

        fetchChainLengthRequest.current = makeCancellable(bikes.fetchChainLengthsByFrame(frame.id));

        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            if (data) {
                setChainRingOptions(data);
                setState({
                    selectedFrameChainLengths: data,
                });
            }

            if (!data) {
                setState({ openDataUnavailableModal: true });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchChainLengthRequest.current = null;
            }
        }
    }

    function onBikeModelChange(value: Array<string | customValueType>) {
        if (!value[0]) {
            setState({ selectedBikeModel: null, selectedBikeModelYear: null });
            return;
        }

        setState({ isFetching: true });

        if (typeof value[0] === 'string') {
            const lastDashIndex = value[0].lastIndexOf('-');
            const selectedBikeName = value[0].slice(0, lastDashIndex).trim();
            const selectedBikeYear = value[0].slice(lastDashIndex + 1).trim();

            const hasHighPivot = bikeModelList.find(
                (bike: bikeModelTypes) => bike.model_name === selectedBikeName && bike.high_pivot,
            );

            if (hasHighPivot) {
                setState({
                    isFetching: false,
                    openNoticeModal: true,
                    selectedChainringSize: null,
                    selectedFrameSize: null,
                });

                return;
            }

            setState({
                isFetching: false,
                linkagePosition: null,
                selectedBikeModel: selectedBikeName,
                selectedBikeModelYear: Number(selectedBikeYear),
                selectedChainringSize: null,
                selectedFrameSize: null,
            });
        }
    }

    async function onBikeBrandChange(value: any, onLoad = false) {
        if (!value) {
            resetSelectedFields();
            return;
        }

        await fetchBikesByBrand(value);

        setState({ isFetching: false });
        if (typeof value === 'string') {
            if (currentQueryParameters && onLoad) {
                const urlBikeModel = currentQueryParameters.get('selectedBikeModel');
                const urlBikeModelYear = currentQueryParameters.get('selectedBikeModelYear');
                if (urlBikeModel && urlBikeModelYear) {
                    setState({
                        ...DEFAULT_STATE,
                        linkagePosition: currentQueryParameters.get('linkagePosition'),
                        selectedBikeBrand: value,
                        selectedBikeModel: urlBikeModel,
                        selectedBikeModelYear: Number(urlBikeModelYear),
                        selectedChainringSize: currentQueryParameters.get('selectedChainringSize'),
                        selectedFrameSize: currentQueryParameters.get('selectedFrameSize'),
                    });
                    return;
                }
            }
            setState({
                ...DEFAULT_STATE,
                selectedBikeBrand: value,
                selectedBikeModel: null,
                selectedBikeModelYear: null,
            });
        }
    }

    function getSelectedModel() {
        return (
            selectedFrameSize && bikeModelList && bikeModelList
                .find((bike: bikeModelTypes) => bike.brand.name === selectedBikeBrand
                    && bike.model_name === selectedBikeModel
                    && bike.year === selectedBikeModelYear
                    && bike.frames.find(({ frame_size }) => frame_size === selectedFrameSize))
        );
    }

    const selectedModel = useMemo(
        () => getSelectedModel(),
        [
            bikeModelList,
            selectedBikeBrand,
            selectedBikeModel,
            selectedBikeModelYear,
            selectedFrameSize,
            selectedBikeFrame,
        ],
    );

    function setSelectedBikeFrame() {
        const selectedBikeFrames = selectedModel && selectedModel.frames.filter(
            ({ frame_size }: { frame_size: string }) => frame_size === selectedFrameSize,
        );

        setState({ selectedBikeFrame: selectedBikeFrames && selectedBikeFrames.length && selectedBikeFrames[0] });

        if (selectedBikeFrames && selectedBikeFrames.length > 1) {
            setState({
                selectedBikeFrame: selectedBikeFrames.find(
                    ({ geometry_setting }: {geometry_setting: string}) => geometry_setting === linkagePosition,
                ),
            });
        }
    }

    const frameSizeList = ((selectedBikeBrand && bikeModelList) && Array.from(new Set(bikeModelList
        .filter(
            (bike: bikeModelTypes) => bike.brand.name === selectedBikeBrand
                                && bike.model_name === selectedBikeModel
                                && bike.year === selectedBikeModelYear,
        )
        .map((model: bikeModelTypes) => model.frames)
        .flat()
        .map((frameSet: frameTypes) => frameSet.frame_size)
        .filter((frameSize: string) => frameSize))))
        || [];

    const selectedBikeFrames = selectedModel && selectedModel.frames.filter(
        ({ frame_size }: { frame_size: string }) => frame_size === selectedFrameSize,
    );

    const positions = (selectedBikeFrames && selectedBikeFrames.length > 1)
            && selectedBikeFrames.filter((frames: frameTypes) => frames.geometry_setting)
                .map((frames: frameTypes) => frames.geometry_setting);

    const getOptionsList = () => {
        switch (modalView) {
            case 'BIKE_BRAND':
                return bikeBrandList.map((brand: any) => ({ label: brand.name }));
            case 'MODEL':
                return bikeModelList.map((bike: bikeModelTypes) => ({ label: `${bike.model_name} - ${bike.year}` }));
            case 'FRAME_SIZE':
                return frameSizeList.map((frameSize: string) => ({ label: frameSize }));
            case 'GEOMETRY':
                return positions.map((position: string) => ({ label: position }));
            case 'CHAINRING_SIZE':
                return chainRingOptions.map((sizes: Record<string, unknown>) => ({ label: sizes.label }));
            default:
                return [];
        }
    };

    const handleSetState = (value: any) => {
        if (!value) return;

        if (value === 'MY_BIKE_NOT_LISTED') {
            setCustomBike(true);
            return;
        }
        switch (modalView) {
            case 'BIKE_BRAND':
                resetSelectedFields();
                onBikeBrandChange(value.label);
                break;
            case 'MODEL':
                onBikeModelChange([value.label]);
                break;
            case 'FRAME_SIZE':
                setState({ selectedFrameSize: value.label });
                break;
            case 'GEOMETRY':
                setState({ linkagePosition: value.label });
                return;
            case 'CHAINRING_SIZE':
                setState({ selectedChainringSize: value.label });
                break;
            default:
                break;
        }
    };

    async function fetchOEMFrameCategories(category: number) {
        if (!category) return null;

        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data && Object.keys(data).length) {
                if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();
                return data;
            }

            if (!data || !Object.keys(data).length) {
                setState({ isCalculating: false, openDataUnavailableModal: true });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchFrameCategoriesRequest.current = null;
                setState({ isCalculating: false });
            }
        }
        return null;
    }

    async function fetchUDH(frame: frameTypes) {
        if (!frame) return null;

        fetchUdhRequest.current = makeCancellable(bikes.fetchUdhByFrame(frame.id));

        try {
            const data = await fetchUdhRequest.current.promise;

            fetchUdhRequest.current = null;
            if (data.length && data[0].category) {
                return data[0].category;
            }

            if (!data.length || !data[0].category) {
                setState({ openDataUnavailableModal: true });
                setState({ isCalculating: false });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchUdhRequest.current = null;
                setState({ isCalculating: false });
                Logger.error('fetchUDH', error);
            }
        }
        return null;
    }

    async function handleFormSubmit() {
        const isValidChainRingSize = chainRingOptions.map((sizes: Record<string, unknown>) => sizes.label)
            .includes(selectedChainringSize);

        if (!selectedModel || !isValidChainRingSize || !selectedBikeFrame) {
            setState({ openDataUnavailableModal: true });
            return;
        }
        setState({ isCalculating: true });
        const udhResponse = await fetchUDH(selectedBikeFrame);
        if (!udhResponse) {
            setState({ openDataUnavailableModal: true });
            return;
        }

        const frameCategories = await fetchOEMFrameCategories(udhResponse);
        if (!frameCategories) {
            setState({ openDataUnavailableModal: true });
            return;
        }

        setState({ isCalculating: false, isFetching: false });
        // eslint-disable-next-line max-len
        navigate(`/guides/mobile/fullmount/chain/calculator/setup?chainLength=${chainLength}&chainStayLength=${chainStayLength}&driveTrainType=MTB&setupCogPosition=${frameCategories.setup_cog_position}&setupCogPositionText=${frameCategories.setup_cog_position_text}&setupKey=${frameCategories.setup_key_position}&setupKeyImage=${frameCategories.setup_key_position_image}&supportedChainRings=${supportedChainRings.join(',')}`);
    }

    const checkUrlParams = () => {
        const urlBikeBrand = currentQueryParameters.get('selectedBikeBrand');
        const bikeBrands = bikeBrandList.map((bike: bikeBrandType) => bike.name);
        if (urlBikeBrand && bikeBrands.includes(urlBikeBrand)) {
            onBikeBrandChange(urlBikeBrand, true);
        } else {
            navigate('/guides/mobile/fullmount/chain/calculator');
        }
    };

    useEffect(() => {
        if (isBikeTypeSelectionOpen) setState({ openSelectionModal: false });
    }, [isBikeTypeSelectionOpen]);

    useEffect(() => {
        if (!bikeBrandList.length) fetchBikeBrands();
    }, []);

    useEffect(() => {
        if (currentQueryParameters && bikeBrandList.length) checkUrlParams();
    }, [bikeBrandList]);

    useEffect(() => {
        // When the user selects a new frame size set the selected bike frame
        setSelectedBikeFrame();
    }, [linkagePosition, selectedFrameSize, selectedModel]);

    useEffect(() => {
        // When the user selects a new frame we fetch the chainlengths and chainring options
        fetchChainLengths(selectedBikeFrame);
    }, [selectedBikeFrame]);

    useEffect(() => {
        // When the user selects a chainring size we calculate the chainlength
        // setSupportedChainRings(selectedChainringSize);
        setSupportedChainRings();
        setChainInformation(selectedBikeFrame);
    }, [selectedChainringSize, selectedFrameChainLengths]);

    const renderOptions = (sectionHeader: string, value: any, disabled?: boolean) => {
        const onClick = () => {
            setOpenBikeTypeSelection(false);
            setState({ openSelectionModal: true });
            setModalView(sectionHeader);
        };

        const renderAltSelectionModal = sectionHeader === modalView;

        return (
            <ContentContainer className={`${styles.optionsContainer} ${disabled ? styles.disabled : ''}`}>
                {renderAltSelectionModal && (
                    <SelectionModalAlt
                        open={openSelectionModal && useAltSelectionModal}
                        onClose={() => setState({ openSelectionModal: false })}
                        setOption={handleSetState}
                        list={getOptionsList()}
                    />
                )}
                <div
                    role="button"
                    className={styles.row}
                    tabIndex={0}
                    onClick={() => {
                        if (!disabled) {
                            setOpenBikeTypeSelection(false);
                            onClick();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !disabled) {
                            setOpenBikeTypeSelection(false);
                            onClick();
                        }
                    }}
                >
                    <div className={styles.selectorTitle}>
                        <Translate>{sectionHeader}</Translate>
                    </div>
                    <div className={styles.selectorTitle}>
                        <Translate>{value || '-- --'}</Translate>
                    </div>
                </div>
            </ContentContainer>
        );
    };

    return (
        <>
            <Helmet>
                <title>Full Mount Chain and Setup Key Guide</title>
                <meta
                    name="description"
                    content="Calculate the perfect chain length for your
                    Eagle Transmission with the Eagle AXS™ Transmission Chain and Setup Key Guide."
                />
            </Helmet>
            <IdlerPullyNotice
                open={state.openNoticeModal}
                onClose={() => setState({ openNoticeModal: false })}
            />
            <DataUnavailableModal
                header="Notice"
                infoText="NO_DATA_CHAINLENGTH_CALCULATOR"
                onClose={() => setState({ openDataUnavailableModal: false })}
                onConfirm={() => {
                    setState({ openDataUnavailableModal: false });
                    setCustomBike(true);
                }}
                open={openDataUnavailableModal}
                options
            />
            {(isFetching || isCalculating) && <Spinner Component={FadeLoader} margin={-12} height={6} width={2} />}
            <>
                <SelectionModal
                    open={openSelectionModal && !useAltSelectionModal}
                    modalTitle={modalView}
                    onClose={() => setState({ openSelectionModal: false })}
                    setOption={handleSetState}
                    list={getOptionsList()}
                />
                {/* Brand Select */}
                {bikeBrandList.length && renderOptions('BIKE_BRAND', selectedBikeBrand)}
                {/* Model Select */}
                {selectedBikeBrand
                   && (bikeModelList || selectedBikeModel) && renderOptions('MODEL', selectedBikeModel)}
                {/* Frame Size Select */}
                {selectedBikeModel
                   && (frameSizeList || selectedFrameSize) && renderOptions('FRAME_SIZE', selectedFrameSize)}
                {/* Geometry Select */}
                {selectedFrameSize && !isFetching && positions.length && renderOptions('GEOMETRY', linkagePosition)}
                {/* Chainring Select */}
                {selectedFrameSize && !isFetching && renderOptions('CHAINRING_SIZE', selectedChainringSize)}
                <div className={styles.formBtns}>
                    <div className={styles.footer}>
                        <button
                            className={styles.submitButton}
                            type="button"
                            onClick={() => handleFormSubmit()}
                            disabled={disableCalcButton}
                            style={{ backgroundColor: disableCalcButton ? SRAM_500 : WHITE }}
                        >
                            <Translate>CALCULATE</Translate>
                        </button>
                        <button
                            className={styles.resetBtn}
                            type="button"
                            onClick={() => {
                                setSelectedBikeType('');
                                resetSelectedFields();
                                clearParams();
                            }}
                        >
                            <Translate>RESET</Translate>
                        </button>
                    </div>
                </div>
            </>
        </>
    );
};

export default MTBFlow;
