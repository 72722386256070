import { scaleTime } from 'd3';
import {
    Grid,
    Line,
    lineBisector,
    onMouseMove,
    onTouchMove,
    Scale,
    VerticalLine,
} from 'd3-charts-react';
import { useMemo } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import ReactResizeDetector from 'react-resize-detector';

import styles from './GearRatioChart.module.scss';

import { EditDrivetrain } from '../gearHistogram';
import GearRatioPlaceholder from '../gearRatioPlaceholder';

import AltitudeChart from '../../altitudeChart';
import { extractTime, extractValue, mutateScaleNice } from '../../chartHelpers';

import Translate from '../../../../components/translate';
import {
    AXS_TEXT_DARK, RED_LIGHT, SRAM_200, toLocaleString,
} from '../../../../constants';
import { useSetState } from '../../../../hooks';
import {
    Activity,
    ComponentSummary,
    useUnits,
} from '../../../../providers';
import ExpandChart from '../../../../views/expandChart';
import Button from '../../../../components/button';
import { Bike, BikeUpdates } from '../../../../providers/bikes/types';

interface GearRatioChartProps {
    activity: Activity,
    bike: Bike,
    disableEdit: boolean,
    gearComponent: ComponentSummary,
    gpsComponent: ComponentSummary,
    updateActivity?: (activity: Activity) => void;
    updateBike: (bikeUpdates: BikeUpdates) => void;
}

const FORMAT_GEAR_RATIO = (gearRatio: string) => Number.parseFloat(gearRatio).toFixed(1);
const DEFAULT_STATE = { altitude: 0, gearRatio: 0, timestamp: null };

const buttonComponent = ({ disabled, onClick }: {disabled: boolean, onClick: () => void}) => (
    <Button
        style={{
            border: `1px solid ${AXS_TEXT_DARK}`,
            color: AXS_TEXT_DARK,
        }}
        color={SRAM_200}
        disabled={disabled}
        inverse
        onClick={onClick}
    >
        <Translate>SELECT</Translate>
    </Button>
);

function GearRatioChart({
    activity,
    bike,
    disableEdit,
    gearComponent,
    gpsComponent,
    updateActivity,
    updateBike,
}: GearRatioChartProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const units = useUnits();
    const { altitude, gearRatio, timestamp } = state;

    const gearRatioExtractor = (...params: any) => {
        const values = lineBisector(...params);

        setState({ gearRatio: values ? values[1] : 0 });
    };

    const generateGearRatios = () => {
        if (!gearComponent
            || !bike
            || !bike.cassette
            || !bike.cassette.teeth
            || !bike.chainring
            || !bike.chainring.teeth) return null;

        let previousKnownChainring = 0;

        const gearRatios = gearComponent.data.adjustedTime.map((_timestamp: number, index: number) => {
            let fdGear = gearComponent.data.fd_gear[index];
            const rdGear = gearComponent.data.rd_gear[index];

            // fdGear can be 6|7 which means unknown
            if (bike.chainring && fdGear >= bike.chainring.teeth.length) {
                fdGear = previousKnownChainring;
            } else if (previousKnownChainring !== fdGear) {
                previousKnownChainring = fdGear;
            }

            const newGearRatio = (bike.chainring.teeth[fdGear] / bike.cassette.teeth[rdGear]);

            return newGearRatio;
        });

        return gearRatios;
    };

    const renderChart = (scale: any, gearRatios: any) => {
        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
        const yAxisProps = axisPropsFromTickScale(scale.y, 3);

        const width = scale.x.range()[1];
        const height = scale.y.range()[0];

        return (
            <div className={styles.chartContainer}>
                <svg
                    className={styles.chart}
                    height={height}
                    onMouseLeave={() => setState({ altitude: 0, gearRatio: 0, timestamp: null })}
                    onMouseMove={(event) => {
                        const hoveredTimeStamp = onMouseMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    onTouchMove={(event) => {
                        const hoveredTimeStamp = onTouchMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    width={width}
                >
                    <AltitudeChart
                        gpsComponent={gpsComponent}
                        height={height}
                        onAltitudeChange={(value: number) => setState({ altitude: value })}
                        sync={timestamp}
                        width={width}
                    />
                    <Grid
                        hideXTicks
                        scale={scale}
                        yLineStyle={{ stroke: SRAM_200 }}
                        yTicks={yAxisProps.values}
                    />
                    <Line
                        data={gearRatios}
                        scale={scale}
                        style={{ stroke: RED_LIGHT }}
                        sync={timestamp}
                        valueExtractor={gearRatioExtractor}
                        xExtractor={(...params: any) => extractTime(gearComponent, ...params)}
                        yExtractor={extractValue}
                    />
                    <VerticalLine scale={scale} sync={timestamp} />
                    {timestamp && (
                        <circle
                            className={styles.indicator}
                            cx={scale.x(timestamp)}
                            cy={Math.min(scale.y(gearRatio), 400)}
                            r={7}
                        />
                    )}
                    <g className={styles.axis}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...yAxisProps}
                            format={FORMAT_GEAR_RATIO}
                            style={{ orient: LEFT }}
                        />
                    </g>
                    <g
                        className={styles.axis}
                        style={{ transform: `translateY(${height}px)` }}
                    >
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...xAxisProps}
                            format={(inTime: number) => units.formatTime(inTime)}
                        />
                    </g>
                </svg>
                <div className={styles.mobileTimeScale}>
                    <div className={styles.startTime}>
                        {units.formatTime(gearComponent.adjustedStartTs * 1000)}
                    </div>
                    <div className={styles.endTime}>
                        {units.formatTime(gearComponent.adjustedEndTs * 1000)}
                    </div>
                </div>
            </div>
        );
    };

    const gearRatios = useMemo(generateGearRatios, [gearComponent, bike]);

    const renderScale = () => {
        if (!gearRatios) return null;

        const max = (bike.chainring && bike.cassette) && (
            Math.max(...bike.chainring.teeth)
            / Math.min(...bike.cassette.teeth)
        );

        return (
            <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                    <Scale
                        height={height}
                        width={width}
                        xMax={gearComponent.adjustedEndTs * 1000}
                        xMin={gearComponent.adjustedStartTs * 1000}
                        xScale={scaleTime}
                        yMax={max * 1.05}
                        yMin={0}
                        yMutator={mutateScaleNice}
                    >
                        {(scale: any) => renderChart(scale, gearRatios)}
                    </Scale>
                )}
            </ReactResizeDetector>
        );
    };

    if (
        !gearComponent
            || !bike
            || !bike.cassette
            || !bike.cassette.teeth
            || !bike.cassette.teeth.length
            || !bike.chainring
            || !bike.chainring.teeth
            || !bike.chainring.teeth.length
    ) {
        return (
            <GearRatioPlaceholder
                activity={activity}
                bike={bike}
                gearComponent={gearComponent}
                updateActivity={updateActivity}
                updateBike={updateBike}
            />
        );
    }

    if (!gearComponent.data.fd_gear.length || !gearComponent.data.rd_gear.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    return (
        <ExpandChart title="GEAR_RATIO">
            <div className={styles.container}>
                <div className={styles.statisticsContainer}>
                    <div className={styles.statistic}>
                        {FORMAT_GEAR_RATIO(gearRatio)}
                    </div>
                    <div className={styles.statistic}>
                        {Number.isFinite(altitude) && (
                            <>
                                {toLocaleString(altitude)}
                                    &nbsp;
                                <Translate>{units.getLabelAltitude().shorthand}</Translate>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.yAxisLabel}>
                    <Translate>GEAR_RATIO</Translate>
                </div>
                {renderScale()}
                <div className={styles.xAxisLabel}>
                    <Translate>TIME</Translate>
                </div>
                {!disableEdit && (
                    <div className={`${styles.footer} ${styles.editDrivetrain}`}>
                        <EditDrivetrain
                            activity={activity}
                            bike={bike}
                            disableEdit={disableEdit}
                            gearComponent={gearComponent}
                            updateActivity={updateActivity as (data: {bike_uuid: string}) => void}
                            updateBike={updateBike}
                            ButtonComponent={buttonComponent}
                        />
                    </div>
                )}
            </div>
        </ExpandChart>
    );
}

export default GearRatioChart;
