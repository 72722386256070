import React from 'react';

// JQuery only included for bootstrap. Do not use jquery.
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import 'd3-charts-react/dist/index.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import ReactModal from 'react-modal';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';

ReactModal.setAppElement('#root');
// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<App />, document.getElementById('root'));
