import { ElementType, ReactNode, useState } from 'react';

import styles from './Button.module.scss';

import {
    SRAM_400,
    SRAM_600,
    SRAM_RED,
    WHITE,
} from '../../constants';

const DISABLED_STYLES = {
    backgroundColor: SRAM_400,
    border: `1px solid ${SRAM_400}`,
    color: WHITE,
    cursor: 'not-allowed',
};

const DISABLED_STYLES_INVERSE = {
    backgroundColor: WHITE,
    border: `1px solid ${SRAM_600}`,
    color: SRAM_600,
    cursor: 'not-allowed',
};

interface ButtonProps {
    children?: ReactNode,
    className?: string,
    color?: string,
    Component?: ElementType
    disabled?: boolean,
    inverse?:boolean,
    onClick?: () => void,
    style?: Record<string, unknown>,
    type?: string,
    id?: string,
    to?: string,
    woopraData?: string,
}

const Button = ({
    children = null,
    className = '',
    color = SRAM_RED,
    // eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
    Component = (props: Record<string, unknown>) => <button {...props} />,
    disabled = false,
    inverse = false,
    onClick = () => {},
    style,
    type = 'button',
    woopraData = '',
    ...otherProps
}: ButtonProps) => {
    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);

    const usePropColor = inverse ? !(hovered || focused) : (hovered || focused);

    const getDisabledStyles = () => {
        if (!disabled) return null;

        if (inverse) return DISABLED_STYLES_INVERSE;

        return DISABLED_STYLES;
    };

    return (
        <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
            data-woopra-track={woopraData}
            disabled={false}
            className={`${styles.button} ${className}`}
            // onBlur={() => setFocused(false)} https://srammies.atlassian.net/browse/DPGC-2428
            onClick={!disabled ? onClick : null}
            // onFocus={() => setFocused(true)} https://srammies.atlassian.net/browse/DPGC-2428
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                backgroundColor: (usePropColor ? WHITE : color),
                border: `1px solid ${color}`,
                color: (usePropColor ? color : WHITE),
                ...getDisabledStyles(),
                ...style,
            }}
            type={type}
        >
            {children}
        </Component>
    );
};

Button.defaultProps = {
    // eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
    Component: (props: Record<string, unknown>) => <button {...props} />,
};

export default Button;
