import { ReactNode } from 'react';
import ReactModal from 'react-modal';

import styles from './Modal.module.scss';

import Translate from '../translate';

interface ModalProps {
    id?: string,
    children?: ReactNode,
    className?: string,
    containerStyle?: Record<string, unknown>,
    contentClassName?: string,
    contentStyle?: Record<string, unknown>,
    headerStyle? : Record<string, unknown>,
    contentLabel: string,
    dialog?: boolean,
    disableOverlayClick?: boolean,
    header?: any,
    hideCloseButton?: boolean,
    hideImage?: boolean,
    imageAlt?: string,
    imageClassName?: string,
    imageSrc?: string,
    imageStyle?: Record<string, unknown>,
    isOpen?: boolean,
    onAfterOpen?: () => void,
    onClose?: () => void,
    onOverlayClick?: () => void,
    overlayClassName?: string,
    darkMode?: boolean,
}

const Modal = ({
    id,
    children = null,
    className = '',
    containerStyle = {},
    contentStyle = {},
    contentClassName = '',
    headerStyle = {},
    contentLabel = '',
    dialog = false,
    disableOverlayClick = false,
    header = null,
    hideCloseButton = false,
    hideImage = false,
    imageAlt = '',
    imageClassName = '',
    imageSrc = '',
    imageStyle = {},
    isOpen = true,
    onAfterOpen = () => {},
    onClose = () => {},
    onOverlayClick = () => {},
    overlayClassName = '',
    darkMode = false,
}: ModalProps) => (
    <ReactModal
        id={id}
        className={`${styles.container} ${dialog ? styles.dialog : ''} ${className}`}
        contentLabel={contentLabel}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        overlayClassName={`${styles.overlay} ${overlayClassName}`}
        shouldCloseOnOverlayClick={!disableOverlayClick}
        style={containerStyle}
        onRequestClose={onOverlayClick || onClose}
    >
        <div className={`${styles.content} ${contentClassName}`} style={contentStyle}>
            {!hideImage && (
                <img
                    alt={imageAlt}
                    className={`${styles.image} ${imageClassName}`}
                    src={imageSrc}
                    style={imageStyle}
                />
            )}
            <div className={`${styles.header} ${hideImage ? styles.headerMarginTopNone : ''} `} style={headerStyle}>
                <Translate>{header}</Translate>
            </div>
            {children}
        </div>
        {!hideCloseButton && (
            <button
                className={darkMode ? `${styles.darkModeCloseBtn}` : `${styles.closeButton}`}
                onClick={onClose}
                type="button"
                aria-label="Close modal"
            />
        )}
    </ReactModal>
);

export default Modal;
