import React from 'react';

import styles from './MobileBikeDropdown.module.scss';

import Drawer from '../../../components/drawer';
import Translate from '../../../components/translate';

interface MobileBikeDropdownProps {
    onClick: () => void;
    onDeleteBike: () => void;
    open: boolean;
    toggleEdit: () => void;
}

const MobileBikeDropdown = ({
    onClick,
    onDeleteBike,
    open,
    toggleEdit,
}: MobileBikeDropdownProps) => (
    <>
        <Drawer isOpen={open} onClose={onClick} subheader="SELECT_OPTION">
            <div className={styles.mobileButtonRow}>
                <button
                    className={`${styles.button} ${styles.actionButton}`}
                    onClick={() => {
                        onClick();
                        toggleEdit();
                    }}
                    type="button"
                >
                    <Translate>BIKE_EDIT</Translate>
                </button>
            </div>
            <div className={styles.mobileButtonRow}>
                <button
                    className={`${styles.button} ${styles.actionButton}`}
                    onClick={() => {
                        onClick();
                        onDeleteBike();
                    }}
                    type="button"
                >
                    <Translate>BIKE_DELETE</Translate>
                </button>
            </div>
        </Drawer>
    </>
);

MobileBikeDropdown.defaultProps = {
    isRetired: false,
    onClick: () => {},
    onDeleteBike: () => {},
    open: false,
    toggleEdit: () => {},
    toggleRetireModal: () => {},
};

export default MobileBikeDropdown;
