import moment from 'moment';
import { bool, shape } from 'prop-types';

import styles from './PowerHistogramStatistics.module.scss';

import { toLocaleString } from '../../../../constants';
import { useNexus } from '../../../../providers/nexus/NexusContext';
import Translate, { useTranslation } from '../../../../components/translate';

const DEFAULT_FTP = 200;

interface PowerHistogramStatisticsProps {
    bin: any;
    isLastBin: boolean;
}

const PowerHistogramStatistics = ({
    bin,
    isLastBin,
}: PowerHistogramStatisticsProps) => {
    if (!bin) {
        return (
            <div className={styles.label}>
                <Translate>FTP_DISTRIBUTION_5_PER_CENT</Translate>
            </div>
        );
    }
    const { nexusUserProfile } = useNexus();
    const translate = useTranslation();
    const ftp = nexusUserProfile ? nexusUserProfile.ftp : DEFAULT_FTP;

    const binRange = (isLastBin && (bin.data.x1 >= ftp * 2))
        ? `${toLocaleString(ftp * 2)}+ ${translate('UNITS_WATTS_SHORT')}`
        // eslint-disable-next-line max-len
        : `${toLocaleString(bin.data.x0)} ${translate('UNITS_WATTS_SHORT')} - ${toLocaleString(bin.data.x1)} ${translate('UNITS_WATTS_SHORT')}`;

    return (
        <div className={styles.container}>
            <div className={styles.statistic}>
                {(moment
                    .duration(bin.total, 'seconds')
                    .format('h[:]mm[:]ss', { stopTrim: 'm' })
                )}
            </div>
            <div className={styles.statistic}>
                {binRange}
            </div>
        </div>
    );
};

PowerHistogramStatistics.defaultProps = { bin: null, isLastBin: false };

PowerHistogramStatistics.propTypes = {
    bin: shape({}),
    isLastBin: bool,
};

export default PowerHistogramStatistics;
