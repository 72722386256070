import {
    useEffect,
    useRef, useState,
} from 'react';
import { Cookies } from 'react-cookie-consent';
import { FadeLoader } from 'react-spinners';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ContentContainer from '../../../components/contentContainer';
import styles from '../mobileChainLengthCalc.module.scss';
import Translate from '../../../components/translate';

import { checkCookieConsent } from '../../../components/analytics/Analytics';
import Logger from '../../../Logger';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import Spinner from '../../../components/spinner';
import { useAuth, useBikes } from '../../../providers';
import {
    CHAINSTAY_LENGTH,
    DROP_BAR_CHAINRING_SIZES, DROP_BAR_CHAINSTAY_LENGTH, makeCancellable, RequestType,
    SRAM_500,
    WHITE,
} from '../../../constants';
import SelectionModal from '../modals/SelectionModal';
import { useSetState } from '../../../hooks';
import DataUnavailableModal from '../modals/DataUnavailableModal';
import SelectionModalAlt from '../modals/SelectionModalAlt';

interface DropBarFlowProps {
    selectedBikeType: string,
    setSelectedBikeType: (value: string) => void,
    setOpenBikeTypeSelection: (value: boolean) => void,
        isBikeTypeSelectionOpen: boolean,
}

const DropBarFlow = ({
    selectedBikeType,
    setSelectedBikeType,
    setOpenBikeTypeSelection,
    isBikeTypeSelectionOpen,
}: DropBarFlowProps) => {
    const { isAuthenticated } = useAuth();
    const bikes = useBikes();
    const navigate = useNavigate();
    const cookieConsent = Cookies.get('CookieConsent');
    const [currentQueryParameters, setSearchParams] = useSearchParams();
    const [state, setState] = useSetState({
        isCalculating: false,
        isFetching: false,
        openSelectionModal: false,
        selectedChainRingSize: '',
        selectedChainStayLength: '',
        selectedDriveTrainType: '',
        showDataUnavailableModal: false,
        showInfoModal: false,
    });
    const [modalView, setModalView] = useState('');
    const {
        isCalculating,
        isFetching,
        selectedChainRingSize,
        selectedChainStayLength,
        showDataUnavailableModal,
        selectedDriveTrainType,
        openSelectionModal,
    } = state;

    const isEagleTransmission = selectedDriveTrainType === 'EAGLE_TRANSMISSION';
    const disableCalcButton = !selectedChainStayLength
                    || !selectedChainRingSize
                    || isCalculating
                    || !selectedDriveTrainType
                    || !selectedBikeType;
    const chainStayOptions = isEagleTransmission ? CHAINSTAY_LENGTH : DROP_BAR_CHAINSTAY_LENGTH;
    const chainRingOptions = DROP_BAR_CHAINRING_SIZES;

    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);

    const clearParams = () => {
        const emptyParams : URLSearchParams = new URLSearchParams();
        setSearchParams(emptyParams);
    };

    const resetState = () => {
        setState({
            chainLength: '',
            chainStayLength: '',
            isCalculating: false,
            openSelectionModal: false,
            selectedChainRingSize: '',
            selectedChainStayLength: '',
            selectedDriveTrainType: '',
            showBikeSetup: false,
            showInfoModal: false,
        });
    };

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            ChainStayLength: selectedChainStayLength,
            CustomChainringSize: selectedChainRingSize,
        };

        const properties = {
            ...eventFields,
            action: 'Custom Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Custom_Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Custom_Chainlength_Calculation', properties);
        }

        Logger.log('CustomChainLengthFormFields', eventFields);
    }

    const handleSetState = (value: any) => {
        if (!value) return;
        switch (modalView) {
            case 'DRIVETRAIN_TYPE':
                setState({ selectedChainRingSize: '', selectedChainStayLength: '', selectedDriveTrainType: value });
                break;
            case 'CHAINRING_SIZE':
                setState({ selectedChainRingSize: value.label });
                break;
            case 'CHAINSTAY_LENGTH':
                setState({ selectedChainStayLength: value.label });
                break;
            default:
                break;
        }
    };

    const getOptionsList = () => {
        switch (modalView) {
            case 'DRIVETRAIN_TYPE':
                return ['XPLR', 'EAGLE_TRANSMISSION'];
            case 'CHAINRING_SIZE':
                return chainRingOptions;
            case 'CHAINSTAY_LENGTH':
                return chainStayOptions;
            default:
                return [];
        }
    };

    async function fetchOEMFrameCategories(category: number) {
        if (!category) return null;

        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data) {
                if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();

                return data;
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchFrameCategoriesRequest.current = null;
                setState({ isCalculating: false });
            }
        }
        return null;
    }

    async function fetchXLPRChainLengths() {
        if (!selectedChainStayLength) return null;

        setState({ isCalculating: true });
        const chainStayValue = Number(selectedChainStayLength.split('mm')[0]);
        fetchChainLengthRequest.current = makeCancellable(
            bikes.fetchChainLengths(
                chainStayValue,
                DROP_BAR_CHAINRING_SIZES[DROP_BAR_CHAINRING_SIZES.length - 1].value,
            ),
        );
        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            const selectedChainLength = data[selectedChainRingSize.split('T')[0]];

            const supportedChainrings: string[] = [];
            Object.entries(data).forEach(([key, value]) => {
                if (value === selectedChainLength) {
                    supportedChainrings.push(`${key}T`);
                }
            });

            if (!data) return null;

            if (selectedChainRingSize) {
                return { chainLength: selectedChainLength, supportedChainRings: supportedChainrings };
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchChainLengthRequest.current = null;
            }
        }
        return null;
    }

    async function calculateChainLength() {
        const chainLengthData = await fetchXLPRChainLengths();

        const category = isEagleTransmission ? 1 : 4;
        const frameCategories = await fetchOEMFrameCategories(category);

        if (chainLengthData?.chainLength && frameCategories) {
            // eslint-disable-next-line max-len
            navigate(`/guides/mobile/fullmount/chain/calculator/setup?driveTrainType=${selectedDriveTrainType}&chainLength=${chainLengthData.chainLength}&chainStayLength=${Number(selectedChainStayLength.split('mm')[0])}&setupCogPosition=${frameCategories.setup_cog_position}&setupCogPositionText=${frameCategories.setup_cog_position_text}&setupKey=${frameCategories.setup_key_position}&setupKeyImage=${frameCategories.setup_key_position_image}&supportedChainRings=${chainLengthData.supportedChainRings.join(',')}`);
            setState({ isCalculating: false, isFetching: false });
            return;
        }
        setState({ isCalculating: false, showDataUnavailableModal: true });
    }

    const renderOptions = (sectionHeader: string, value: string | number) => (
        <ContentContainer className={styles.optionsContainer}>
            {sectionHeader === 'DRIVETRAIN_TYPE' && (
                <SelectionModalAlt
                    open={openSelectionModal && modalView === 'DRIVETRAIN_TYPE'}
                    onClose={() => setState({ openSelectionModal: false })}
                    setOption={handleSetState}
                    list={['XPLR', 'EAGLE_TRANSMISSION']}
                />
            )}
            <div
                role="button"
                className={styles.row}
                tabIndex={0}
                onClick={() => {
                    setState({ openSelectionModal: true });
                    setOpenBikeTypeSelection(false);
                    setModalView(sectionHeader);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setState({ openSelectionModal: true });
                        setOpenBikeTypeSelection(false);
                        setModalView(sectionHeader);
                    }
                }}
            >
                <div className={styles.selectorTitle}>
                    <Translate>{sectionHeader}</Translate>
                </div>
                <div className={styles.selectorTitle}>
                    <Translate>{value || '-- --'}</Translate>
                </div>
            </div>
        </ContentContainer>
    );

    const checkUrlParams = () => {
        const rdType = currentQueryParameters.get('rd_type');

        if (rdType) {
            setState({ selectedDriveTrainType: rdType });
        } else {
            navigate('/guides/mobile/fullmount/chain/calculator');
        }

        setState({
            selectedChainRingSize: currentQueryParameters.get('selectedChainringSize'),
            selectedChainStayLength: currentQueryParameters.get('selectedChainstay'),
        });
    };

    useEffect(() => {
        if (isBikeTypeSelectionOpen) setState({ openSelectionModal: false });
    }, [isBikeTypeSelectionOpen]);

    useEffect(() => {
        resetState();
    }, [selectedBikeType]);

    useEffect(() => {
        if (currentQueryParameters) checkUrlParams();
    }, []);

    return (
        <>
            <DataUnavailableModal
                open={showDataUnavailableModal}
                onClose={() => setState({ showDataUnavailableModal: false })}
                onConfirm={() => setState({ showDataUnavailableModal: false })}
                header="Data Unavailable"
                infoText="MOBILE_DATA_UNAVAILABLE_DROPBAR"
                options={false}
            />
            { (isFetching || isCalculating) && <Spinner Component={FadeLoader} margin={-12} height={6} width={2} />}

            <>

                <SelectionModal
                    open={openSelectionModal && modalView !== 'DRIVETRAIN_TYPE'}
                    modalTitle={modalView}
                    onClose={() => setState({ openSelectionModal: false })}
                    setOption={handleSetState}
                    list={getOptionsList()}
                />
                {renderOptions('DRIVETRAIN_TYPE', selectedDriveTrainType)}
                {selectedDriveTrainType
                   && (
                       <>
                           {renderOptions('CHAINSTAY_LENGTH', selectedChainStayLength)}
                           {renderOptions('CHAINRING_SIZE', selectedChainRingSize)}
                       </>
                   )}
                {selectedBikeType && (
                    <div className={styles.formBtns}>
                        <div className={styles.footer}>
                            <button
                                className={styles.submitButton}
                                type="button"
                                onClick={() => calculateChainLength()}
                                disabled={disableCalcButton}
                                style={{ backgroundColor: disableCalcButton ? SRAM_500 : WHITE }}
                            >
                                <Translate>CALCULATE</Translate>
                            </button>
                            <button
                                className={styles.resetBtn}
                                type="button"
                                onClick={() => {
                                    setSelectedBikeType('');
                                    clearParams();
                                }}
                            >
                                <Translate>RESET</Translate>
                            </button>
                        </div>
                    </div>
                )}
            </>
        </>
    );
};

export default DropBarFlow;
