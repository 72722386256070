import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FormEvent, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import styles from './AddBikeComponent.module.scss';

import { iconDelete, iconHelp } from '../../../assets';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import Spinner from '../../../components/spinner';
import Translate, { useTranslation } from '../../../components/translate';
import TranslateOption from '../../../components/translateOption';
import {
    AXS_TEXT_GREY, makeCancellable, RED, RequestType,
} from '../../../constants';
import { useSetState } from '../../../hooks';
import { Bike, useBikeComponents, useBikes } from '../../../providers';
import BikeCard from '../../../views/bikeCard';
import BikeForm from '../../../views/bikeForm';
import ErrorModal from '../../../views/errorModal';

const DEFAULT_STATE = {
    assignedBike: null,
    error: null,
    imagePreview: null,
    inlineError: null,
    isDeleting: false,
    isRegistering: false,
    registeredComponents: [],
    selectedBikeComponent: null,
    serial: null,
    showAddComponentModal: false,
    showDeleteModal: false,
    showNewBikeForm: false,
};

interface AddBikeComponentProps {
    bike?:Bike
}

const AddBikeComponent = ({ bike }: AddBikeComponentProps) => {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const {
        assignedBike,
        error,
        imagePreview,
        inlineError,
        isDeleting,
        isRegistering,
        registeredComponents,
        selectedBikeComponent,
        serial,
        showAddComponentModal,
        showDeleteModal,
        showNewBikeForm,
    } = state;
    const bikeComponents = useBikeComponents();
    const bikes = useBikes();
    const translate = useTranslation();
    const addBikeRequest = useRef<RequestType | null>(null);
    const createNexusComponentRequests = useRef(new Map());
    const deleteBikeComponentRequests = useRef(new Map());
    const registerComponentRequests = useRef(new Map());

    useEffect(() => {
        if (bike) {
            setState({ assignedBike: bike });
        }

        return () => {
            if (addBikeRequest.current) {
                addBikeRequest.current.cancel();
                addBikeRequest.current = null;
            }

            createNexusComponentRequests.current.forEach((request) => request.cancel());
            createNexusComponentRequests.current.clear();

            deleteBikeComponentRequests.current.forEach((request) => request.cancel());
            deleteBikeComponentRequests.current.clear();

            registerComponentRequests.current.forEach((request) => request.cancel());
            registerComponentRequests.current.clear();
        };
    }, []);

    const deleteBikeComponent = async () => {
        setState({ isDeleting: true, showDeleteModal: false });

        const fetchRequest = makeCancellable(bikeComponents.deleteBikeComponent(selectedBikeComponent.id));
        deleteBikeComponentRequests.current.set(selectedBikeComponent.id, fetchRequest);

        try {
            await fetchRequest.promise;

            setState({
                isDeleting: false,
                registeredComponents: registeredComponents.filter(({ bikeComponent }: any) => (
                    bikeComponent.id !== selectedBikeComponent.id
                )),
                selectedBikeComponent: null,
            });

            deleteBikeComponentRequests.current.delete(selectedBikeComponent);
        } catch (err: any) {
            if (!err.isCancelled) {
                setState({ error: new Error('COMPONENT_DELETE_ERROR'), isDeleting: false });
                deleteBikeComponentRequests.current.delete(selectedBikeComponent);
            }
        }
    };

    const saveBike = async (bikeToSave: Bike) => {
        if (addBikeRequest.current) {
            try {
                await addBikeRequest.current.promise;

                return;
            } catch (err) {
                return;
            }
        }

        if (!bikeToSave.name || !bikeToSave.name.length) return;

        setState({ isRegistering: true });

        addBikeRequest.current = makeCancellable(bikes.addBike(bikeToSave));

        try {
            const newBike = await addBikeRequest.current.promise;

            addBikeRequest.current = null;

            if (newBike) {
                setState({
                    assignedBike: newBike,
                    imagePreview: null,
                    isRegistering: false,
                    showNewBikeForm: false,
                });
            } else {
                setState({ error: new Error('BIKE_NEW_ERROR'), isRegistering: false });
            }
        } catch (err: any) {
            if (!err.isCancelled) {
                addBikeRequest.current = null;
                setState({ error: new Error('BIKE_NEW_ERROR'), isRegistering: false });
            }
        }
    };

    const createNexusComponent = async (data: any) => {
        const fetchRequest = makeCancellable(
            bikeComponents.createNexusComponent(serial, assignedBike && assignedBike.id),
        );

        createNexusComponentRequests.current.set(serial, fetchRequest);

        try {
            const bikeComponent = await fetchRequest.promise;

            createNexusComponentRequests.current.delete(serial);

            if (data) {
                setState({
                    assignedBike: bike,
                    isRegistering: false,
                    registeredComponents: [
                        ...registeredComponents,
                        { bike: assignedBike, bikeComponent, serialNumber: serial },
                    ],
                    serial: null,
                });

                return;
            }

            setState({ error: new Error('ADD_COMPONENT_ERROR'), isRegistering: false });
        } catch (err: any) {
            if (!err.isCancelled) {
                createNexusComponentRequests.current.delete(serial);
                setState({ error: new Error('ADD_COMPONENT_ERROR'), isRegistering: false });
            }
        }
    };

    const submitComponentRegistration = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!serial) return;

        setState({ isRegistering: true });
        const fetchRequest = makeCancellable(bikeComponents.registerComponent(serial));

        registerComponentRequests.current.set(serial, fetchRequest);

        try {
            const data = await fetchRequest.promise;

            registerComponentRequests.current.delete(serial);

            if (!data) {
                setState({ error: new Error('ADD_COMPONENT_ERROR'), isRegistering: false });

                return;
            }

            if (data && assignedBike) {
                createNexusComponent(data);

                return;
            }

            setState({
                assignedBike: bike,
                isRegistering: false,
                registeredComponents: [
                    ...registeredComponents,
                    { bikeComponent: data, serialNumber: serial },
                ],
                serial: null,
            });
        } catch (err: any) {
            if (!err.isCancelled) {
                registerComponentRequests.current.delete(serial);

                if (err.response && err.response.data) {
                    setState({ inlineError: err.response.data.component, isRegistering: false });

                    return;
                }

                setState({ inlineError: translate('ADD_COMPONENT_ERROR'), isRegistering: false });
            }
        }
    };

    const renderAssignBikeForm = () => {
        const selectedBike = assignedBike || bike;

        return (
            <div className={styles.assignBikeContainer}>
                <div className={styles.inputLabel}>
                    <Translate>ADD_TO_BIKE</Translate>
                    &nbsp;
                    (
                    <span className={styles.optional}>
                        <Translate>INPUT_OPTIONAL</Translate>
                    </span>
                    )
                </div>
                <div className={styles.selectContainer}>
                    <select
                        className={styles.bikeInput}
                        onChange={(event) => {
                            if (event.target.value === 'BIKE_ADD_NEW') {
                                setState({ showNewBikeForm: !showNewBikeForm });
                            } else {
                                setState({
                                    assignedBike: bikes.list.find(({ name }) => name === event.target.value),
                                });
                            }
                        }}
                        value={selectedBike ? selectedBike.name : ''}
                    >
                        <TranslateOption disabled hidden value="">BIKE_ASSIGN</TranslateOption>
                        {bikes.list.map(({ name, id }) => (
                            <option key={id} value={name}>
                                {name}
                            </option>
                        ))}
                        <TranslateOption key="BIKE_ADD_NEW" value="BIKE_ADD_NEW">
                            BIKE_ADD_NEW_DOTTED
                        </TranslateOption>
                    </select>
                </div>
            </div>
        );
    };

    const renderDeleteConfirmation = () => {
        if (!selectedBikeComponent) return null;

        return (
            <Modal
                contentLabel="COMPONENT_DELETE_WARNING"
                dialog
                header="ARE_YOU_SURE"
                hideCloseButton
                isOpen={showDeleteModal}
                imageStyle={{ height: '5rem', marginBottom: 0, marginTop: 'auto' }}
                imageSrc={iconDelete}
            >
                <Translate
                    params={{
                        name: selectedBikeComponent.name || selectedBikeComponent.mobile_display_name_key,
                    }}
                >
                    COMPONENT_DELETE_WARNING
                </Translate>
                <div className={styles.deleteButtonsContainer}>
                    <Button
                        color={AXS_TEXT_GREY}
                        inverse
                        onClick={() => setState({ selectedBikeComponent: null, showDeleteModal: false })}
                    >
                        <Translate>CANCEL</Translate>
                    </Button>
                    <Button type="button" onClick={() => deleteBikeComponent()}>
                        <Translate>DELETE</Translate>
                    </Button>
                </div>
            </Modal>
        );
    };

    const renderNewBikeForm = () => {
        if (!showNewBikeForm) return null;

        return (
            <div className={styles.bikeFormContainer}>
                <BikeCard bike={{ name: translate('BIKE_NEW_PROFILE') }} image={imagePreview} />
                <BikeForm
                    nameIsRequired
                    onImageSelected={(newImagePreview) => setState({ imagePreview: newImagePreview })}
                    onSubmit={(bikeToSave) => saveBike(bikeToSave)}
                >
                    <div className={styles.bikeButtonsContainer}>
                        <Button
                            className={styles.buttonEditBike}
                            color={AXS_TEXT_GREY}
                            inverse
                            onClick={() => setState({ showNewBikeForm: !showNewBikeForm })}
                        >
                            <Translate>CANCEL</Translate>
                        </Button>
                        <Button className={styles.buttonEditBike} type="submit">
                            <Translate>SAVE</Translate>
                        </Button>
                    </div>
                </BikeForm>
            </div>
        );
    };

    const renderRegisteredSerialNumbers = () => {
        if (!registeredComponents.length) return null;

        return (
            <>
                <div className={styles.divider} />
                <div className={styles.registeredContent}>
                    <table className={styles.serialNumbersTable}>
                        <thead>
                            <tr className={styles.tableHeader}>
                                <th className={styles.tableHeaderColumn}>
                                    <Translate>REGISTERED_SERIAL_NUMBERS</Translate>
                                </th>
                                <th className={styles.tableHeaderColumn}>
                                    <Translate>BIKE</Translate>
                                </th>
                                <th className={`${styles.tableHeaderColumn} ${styles.deleteColumn}`}>
                                    <Translate>DELETE</Translate>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {registeredComponents.map(({ bike: registeredBike, bikeComponent, serialNumber }: any) => (
                                <tr className={styles.row} key={serialNumber}>
                                    <td className={`${styles.column} ${styles.serialColumn}`}>
                                        <CheckCircleIcon className={styles.checkIcon} />
                                        <Link
                                            className={styles.link}
                                            to={`/components/${bikeComponent.component || bikeComponent.id}`}
                                        >
                                            {serialNumber}
                                        </Link>
                                    </td>
                                    <td className={`${styles.column} ${styles.bikeColumn}`}>
                                        {registeredBike && (
                                            <Link className={styles.link} to={`/bikerack/${registeredBike.id}`}>
                                                {registeredBike.name}
                                            </Link>
                                        )}
                                        {!registeredBike && (
                                            <Translate>NO_BIKE_SELECTED</Translate>
                                        )}
                                    </td>
                                    <td className={`${styles.column} ${styles.deleteColumn}`}>
                                        <button
                                            className={styles.cancelButton}
                                            onClick={() => setState({
                                                selectedBikeComponent: bikeComponent,
                                                showDeleteModal: true,
                                            })}
                                            type="button"
                                        >
                                            <CancelIcon className={styles.cancelIcon} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.doneButton}>
                        <Button
                            inverse
                            onClick={() => setState({
                                assignedBike: null,
                                inlineError: null,
                                registeredComponents: [],
                                serial: null,
                                showAddComponentModal: false,
                            })}
                            type="submit"
                        >
                            <Translate>DONE</Translate>
                        </Button>
                    </div>
                </div>
            </ >
        );
    };

    const renderProductRegistrationForm = () => {
        if (showNewBikeForm) return null;

        return (
            <>
                <div className={styles.productRegisterContainer}>
                    <div className={styles.productRegisterHeader}>
                        <div className={styles.productRegisterTitle}>
                            <Translate>SRAM_PRODUCT_REGISTRATION</Translate>
                        </div>
                        <Translate>SRAM_PRODUCT_REGISTRATION_DESCRIPTION</Translate>
                    </div>
                    <form onSubmit={(event) => submitComponentRegistration(event)}>
                        <div className={styles.form}>
                            <div className={styles.inputs}>
                                <div className={styles.serial}>
                                    <div className={styles.inputLabel}>
                                        <Translate>SERIAL_NUMBER</Translate>
                                        {/* eslint-disable-next-line max-len */}
                                        <a href="https://www.sram.com/globalassets/document-hierarchy/service-manuals/component-serial-number-locator.pdf" rel="noopener noreferrer" target="_blank">
                                            {/* eslint-disable-next-line max-len */}
                                            <img alt="" className={styles.icon} src={iconHelp} />
                                        </a>
                                    </div>
                                    <input
                                        className={styles.serialInput}
                                        onChange={(event) => setState({
                                            inlineError: null,
                                            serial: event.target.value,
                                        })}
                                        placeholder={translate('ENTER')}
                                        required
                                        style={inlineError !== null ? { border: `1px solid ${RED}` } : {}}
                                        value={serial || ''}
                                    />
                                </div>
                                {renderAssignBikeForm()}
                            </div>
                            <span className={styles.errorMessage}>
                                {inlineError}
                            </span>
                        </div>
                        <div className={styles.submitButton}>
                            <Button type="submit">
                                <Translate>SUBMIT</Translate>
                            </Button>
                        </div>
                    </form>
                </div>
                {renderRegisteredSerialNumbers()}
            </ >
        );
    };

    const renderAddComponentModal = () => {
        if (error) {
            return (
                <ErrorModal
                    error={error}
                    onClose={() => setState({ error: null })}
                    onOverlayClick={() => setState({ error: null, showNewBikeForm: false })}
                />
            );
        }

        return (
            <Modal
                containerStyle={(showNewBikeForm
                    ? { content: { maxHeight: '100vh', padding: 0 } }
                    : { content: { minHeight: 'initial' } }
                )}
                contentLabel="add component"
                contentStyle={showNewBikeForm ? { color: 'initial', padding: 0, paddingBottom: '1rem' } : undefined}
                hideCloseButton={showNewBikeForm}
                hideImage
                isOpen={showAddComponentModal}
                onClose={() => setState({
                    assignedBike: null,
                    inlineError: null,
                    registeredComponents: [],
                    serial: null,
                    showAddComponentModal: false,
                })}
            >
                {renderProductRegistrationForm()}
                {renderNewBikeForm()}
            </Modal>
        );
    };

    return (
        <div className={styles.container}>
            <Spinner loading={isRegistering || isDeleting} />
            <div className={styles.header}>
                <Translate>ADD_COMPONENT_HEADER_NOTE</Translate>
            </div>
            <div className={styles.addComponentButtonContainer}>
                <button
                    className={styles.addComponentButton}
                    type="button"
                    onClick={() => setState({ showAddComponentModal: true })}
                >
                    <Translate>ADD_COMPONENT</Translate>
                </button>
            </div>
            {renderAddComponentModal()}
            {renderDeleteConfirmation()}
        </div>
    );
};

export default AddBikeComponent;
