import React, { useEffect, useRef, useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Cookies } from 'react-cookie-consent';
import styles from './CustomChain.module.scss';
import ContentContainer from '../../../components/contentContainer';
import Translate, { useTranslation } from '../../../components/translate';
import {
    CHAINRING_SIZES,
    CHAINSTAY_LENGTH,
    DROP_BAR_CHAINRING_SIZES, DROP_BAR_CHAINSTAY_LENGTH, makeCancellable, RequestType,
} from '../../../constants';
import Modal from '../../../components/modal';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import TranslateOption from '../../../components/translateOption';
import { imageHowToMeasure } from '../../../assets';
import { useAuth, useBikes } from '../../../providers';
import { checkCookieConsent } from '../../../components/analytics/Analytics';
import Logger from '../../../Logger';
import Spinner from '../../../components/spinner';
import XPLRChainLengthModal from '../XPLRChainLengthModal/XPLRChainLengthModal';
import EagleChainLengthModal from '../eagleChainLengthModal/EagleChainLengthModal';

function CustomChain({ driveTrainType }: {driveTrainType: string}) {
    const { isAuthenticated } = useAuth();
    const cookieConsent = Cookies.get('CookieConsent');
    const [chainLength, setChainLength] = useState< null | number>(null);
    const [chainRingSize, setChainRingSize] = useState<string>('');
    const [chainStayLength, setChainStayLength] = useState<string>('');
    const [supportedChainRings, setSupportedChainRings] = useState<[]>([]);
    const [openSetUpInfoModal, setOpenSetUpInfoModal] = useState(false);
    const [isCalculating, setIsCalculating] = useState(false);
    const [openChainLengthModal, setOpenChainLengthModal] = useState<boolean>(false);
    const [categoryInfo, setCategoryInfo] = useState<any>(null);
    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);

    const bikes = useBikes();
    const translate = useTranslation();
    const isEagleTransmission = driveTrainType === 'EAGLE_TRANSMISSION';

    const chainStayOptions = isEagleTransmission ? CHAINSTAY_LENGTH : DROP_BAR_CHAINSTAY_LENGTH;
    const chainRingOptions = DROP_BAR_CHAINRING_SIZES;

    useEffect(() => {
        setChainStayLength('');
        setOpenChainLengthModal(false);
        setChainRingSize('');
        setCategoryInfo(null);

        return (() => {
            if (fetchChainLengthRequest.current) {
                fetchChainLengthRequest.current.cancel();
                fetchChainLengthRequest.current = null;
            }

            if (fetchFrameCategoriesRequest.current) {
                fetchFrameCategoriesRequest.current.cancel();
                fetchFrameCategoriesRequest.current = null;
            }
        });
    }, []);

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            ChainStayLength: chainStayLength,
            CustomChainringSize: chainRingSize,
        };

        const properties = {
            ...eventFields,
            action: 'Custom Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Custom_Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Custom_Chainlength_Calculation', properties);
        }

        Logger.log('CustomChainLengthFormFields', eventFields);
    }

    async function fetchOEMFrameCategories(category: number) {
        if (!category) return;

        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data) {
                setCategoryInfo(data);
                setOpenChainLengthModal(true);
                setIsCalculating(false);

                if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchFrameCategoriesRequest.current = null;
                setIsCalculating(false);
            }
        }
    }

    async function fetchChainLengths() {
        setIsCalculating(true);
        fetchChainLengthRequest.current = makeCancellable(
            bikes.fetchChainLengths(
                Number(chainStayLength),
                DROP_BAR_CHAINRING_SIZES[DROP_BAR_CHAINRING_SIZES.length - 1].value,
            ),
        );
        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            const selectedChainLength = data[chainRingSize.split('T')[0]];

            const supportedChainrings: any = [];
            Object.entries(data).forEach(([key, value]) => {
                if (value === selectedChainLength) {
                    supportedChainrings.push(`${key}T`);
                }
            });
            if (!data) return;

            if (chainRingSize) {
                setSupportedChainRings(supportedChainrings);
                setChainLength(selectedChainLength);
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchChainLengthRequest.current = null;
            }
        }
    }

    async function calculateChainLength() {
        fetchChainLengths();

        const category = driveTrainType === 'XPLR' ? 4 : 1;
        fetchOEMFrameCategories(category);
    }

    function renderInfoModal() {
        return (
            <Modal
                className={styles.modalContainer}
                contentClassName={styles.infoContent}
                contentLabel="InfoModal"
                isOpen={openSetUpInfoModal}
                header="HOW_TO_MEASURE"
                hideImage
                onClose={() => setOpenSetUpInfoModal(false)}
            >
                <div className={styles.setupGuideContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.infoSubHeader}>
                            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH_TITLE</Translate>
                        </div>
                        <div className={styles.infoDetail}>
                            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH</Translate>
                        </div>
                        <div className={styles.infoSubHeader}>
                            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE_TITLE</Translate>
                        </div>
                        <div className={styles.infoDetail}>
                            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE</Translate>
                        </div>
                    </div>
                    <div className={styles.imageContainer}>
                        <img
                            alt="Setup Guide"
                            className={styles.image}
                            src={imageHowToMeasure}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <>
            {!isEagleTransmission && (
                <XPLRChainLengthModal
                    bike={translate('DROP_BAR_XPLR_FULL_MOUNT')}
                    chainRingSize={chainRingSize}
                    chainLength={chainLength}
                    chainStayLength={Number(chainStayLength)}
                    isOpen={openChainLengthModal}
                    onClose={() => setOpenChainLengthModal(false)}
                    setUpCogPosition={categoryInfo && categoryInfo.setup_cog_position}
                    setUpCogPositionText={categoryInfo && categoryInfo.setup_cog_position_text}
                    supportedChainrings={supportedChainRings}
                />
            )}
            {isEagleTransmission && (
                <EagleChainLengthModal
                    bike={translate('EAGLE_TRANSMISSION')}
                    chainLength={chainLength}
                    chainStayLength={Number(chainStayLength)}
                    isOpen={openChainLengthModal}
                    onClose={() => setOpenChainLengthModal(false)}
                    supportedChainrings={supportedChainRings}
                    setUpCogPosition={categoryInfo && categoryInfo.setup_cog_position}
                    setUpCogPositionText={categoryInfo && categoryInfo.setup_cog_position_text}
                    setUpCogImage={categoryInfo && categoryInfo.setup_cog_position_image}
                    setUpKeyPos={categoryInfo && categoryInfo.setup_key_position_text}
                    setUpKeyPosImage={categoryInfo && categoryInfo.setup_key_position_image}
                />
            )}
            <ContentContainer className={styles.optionsContainer}>
                { isCalculating && <Spinner />}
                <div className={styles.row}>
                    <div className={styles.selector}>
                        <div className={styles.label}>
                            <Translate>CHAINSTAY_LENGTH_AND_CHAINRING_SIZE</Translate>
                            <button
                                className={styles.helpButton}
                                onClick={() => setOpenSetUpInfoModal(true)}
                                id="infoButton"
                                type="button"
                                data-testid="customChain-infoButton"
                            >
                                <InfoOutlinedIcon />
                            </button>
                        </div>
                        <div className={styles.content}>
                            <select
                                data-testid="chainstay-length"
                                className={styles.inputField}
                                onChange={(event) => setChainStayLength(event.target.value)}
                                value={chainStayLength}
                            >
                                <TranslateOption value="">CHAINSTAY_SELECTION</TranslateOption>
                                {chainStayOptions.map(({ label, value }) => (
                                    <option key={label} value={value}>{label}</option>
                                ))}
                            </select>
                            <select
                                data-testid="chainring-size"
                                className={styles.inputField}
                                onChange={(event) => setChainRingSize(event.target.value)}
                                value={chainRingSize}
                            >
                                <TranslateOption value="">CHAINRING_SELECTION</TranslateOption>
                                {chainRingOptions.map(({ label }) => (
                                    <option key={label} value={label}>{label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {renderInfoModal()}
                {chainStayLength !== '' && chainRingSize !== '' && (
                    <div className={styles.footer}>
                        <button
                            className={styles.submitButton}
                            onClick={() => calculateChainLength()}
                            type="button"
                        >
                            <Translate>SUBMIT</Translate>
                        </button>
                    </div>
                )}
            </ContentContainer>
        </>
    );
}

export default CustomChain;
