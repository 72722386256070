import axios from 'axios';
import { ReactNode, useEffect, useRef } from 'react';

import BikesContext from './BikesContext';

import { useAuth } from '../auth/AuthContext';
import { useNexus } from '../nexus/NexusContext';

import {
    generateUUID,
    makeCancellable,
    RequestType,
    URL_API,
    URL_CLOUSEAU,
    URL_CLOUSEAU_API,
    URL_STATIC_DATA,
} from '../../constants';
import Logger from '../../Logger';
import { useSetState } from '../../hooks';
import { Bike } from './types';

const DEFAULT_STATE = {
    bikes: [],
    cassettes: [],
    chainrings: [],
    componentsSet: {},
    hasMore: true,
    isFetching: false,
    notificationSet: {},
    page: 1,
};
const EXAMPLE = 'example';
const URL_BIKES = `${URL_API}bikes/`;

interface BikesProviderProps { children : ReactNode }

const serialiseBike = (bike: any) => {
    // Convert the data to a FormData object
    const bikeFormData = new FormData();
    Object.entries(bike).forEach(([key, value]: any) => {
        // Check if the value is an object but not a file or blob
        if (!(value instanceof File || value instanceof Blob)
            && (typeof value === 'object' || Array.isArray(value))
        ) {
            bikeFormData.append(key, JSON.stringify(value));
        } else {
            bikeFormData.append(key, value);
        }
    });

    return bikeFormData;
};

function BikesProvider({ children }: BikesProviderProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const {
        bikes,
        componentsSet,
        hasMore,
        notificationSet,
        page,
    } = state;
    const auth = useAuth();
    const nexus = useNexus();

    const bikeAddRequests = useRef(new Set<RequestType>());
    const bikeFetchRequests = useRef(new Map<string, RequestType>());
    const bikeDeleteRequests = useRef(new Map<string, RequestType>());
    const bikesRequest = useRef<RequestType | null>(null);
    const bikeUpdateRequests = useRef(new Set<RequestType>());
    const fetchBikeExampleRequest = useRef<RequestType | null>(null);
    const fetchBikeModelBrandsRequest = useRef<RequestType | null>(null);
    const fetchBikesByBrandRequest = useRef<RequestType | null>(null);
    const fetchCassettesRequest = useRef<RequestType | null>(null);
    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchChainringsRequest = useRef<RequestType | null>(null);
    const fetchChainLengthByFrameRequest = useRef<RequestType | null>(null);
    const fetchDefaultCassettesRequest = useRef<RequestType | null>(null);
    const fetchDefaultChainringsRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);
    const fetchUdhByFrameRequest = useRef<RequestType | null>(null);
    const fetchUdhByChainLengthRequest = useRef<RequestType | null>(null);

    const initialBikesTotal = useRef<number>(0);

    const fetchBikeComponentSetRequest = useRef(new Map<string, RequestType>());
    const fetchBikeNotificationSetRequest = useRef(new Map<string, RequestType>());

    async function clear() {
        bikeAddRequests.current.forEach((request) => request.cancel());
        bikeAddRequests.current.clear();

        bikeDeleteRequests.current.forEach((request) => request.cancel());
        bikeDeleteRequests.current.clear();

        bikeFetchRequests.current.forEach((request) => request.cancel());
        bikeFetchRequests.current.clear();

        // Cancel requests when the component unmounts
        if (bikesRequest.current) {
            bikesRequest.current.cancel();
            bikesRequest.current = null;
        }

        bikeUpdateRequests.current.forEach((request) => request.cancel());
        bikeUpdateRequests.current.clear();

        initialBikesTotal.current = 0;

        if (fetchBikeModelBrandsRequest.current) {
            fetchBikeModelBrandsRequest.current.cancel();
        }

        if (fetchBikesByBrandRequest.current) {
            fetchBikesByBrandRequest.current.cancel();
        }

        if (fetchCassettesRequest.current) {
            fetchCassettesRequest.current.cancel();
        }

        if (fetchChainringsRequest.current) {
            fetchChainringsRequest.current.cancel();
        }

        if (fetchChainLengthRequest.current) {
            fetchChainLengthRequest.current.cancel();
        }

        if (fetchChainLengthByFrameRequest.current) {
            fetchChainLengthByFrameRequest.current.cancel();
        }

        if (fetchDefaultCassettesRequest.current) {
            fetchDefaultCassettesRequest.current.cancel();
        }

        if (fetchDefaultChainringsRequest.current) {
            fetchDefaultChainringsRequest.current.cancel();
        }

        if (fetchFrameCategoriesRequest.current) {
            fetchFrameCategoriesRequest.current.cancel();
        }

        if (fetchUdhByFrameRequest.current) {
            fetchUdhByFrameRequest.current.cancel();
        }

        fetchBikeComponentSetRequest.current.forEach((request) => request.cancel());
        fetchBikeNotificationSetRequest.current.forEach((request) => request.cancel());

        setState({ ...DEFAULT_STATE });
    }

    function sortBikes() {
        const newBikeArray = [
            ...bikes.filter((item: Bike) => !item.data || !item.data.retired)
                .sort((a: Bike, b: Bike) => (b.last_history_date - a.last_history_date)),
            ...bikes.filter((item: Bike) => item.data && item.data.retired)
                .sort((a: Bike, b: Bike) => (b.last_history_date - a.last_history_date)),
        ];
        setState({ newBikeArray });
    }

    async function fetchBikes() {
        // Don't refetch is fetching
        if (bikesRequest.current) {
            try {
                const { data } = await bikesRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }
        if (!hasMore) {
            return null;
        }
        setState({ isFetching: true });

        // Make the bikesRequest cancellable
        bikesRequest.current = makeCancellable(axios.get(
            URL_BIKES,
            { params: { page } },
        ));
        try {
            const { data, headers } = await bikesRequest.current.promise;
            bikesRequest.current = null;
            if (page === 1) {
                initialBikesTotal.current = Number.parseInt(headers['total-count']);
            }

            // Only assign new bikes
            const newBikes: Bike[] = [
                ...bikes.filter(({ id }: {id: string}) => !data.find((newBike: Bike) => newBike.id === id)),
                ...data,
            ];
            setState({
                bikes: newBikes,
                hasMore: newBikes.length < initialBikesTotal.current,
                isFetching: false,
                page: page + 1,
            });

            sortBikes();

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching bikes', page, error);
                bikesRequest.current = null;
                setState({ hasMore: false, isFetching: false });
            }

            return null;
        }
    }

    function getBike(uuid: string) {
        /* eslint-disable eqeqeq */
        return bikes.find((bike: Bike) => bike.id == uuid);
    }

    async function addBike(newBike: Bike) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        const newBikeFormData = serialiseBike(newBike);

        // Start the fetch request
        const fetchRequest = makeCancellable(axios.put(
            `${URL_BIKES}${generateUUID()}/`,
            newBikeFormData,
            { headers },
        ));

        // Add the cancellable promise to the set
        bikeAddRequests.current.add(fetchRequest);
        try {
            const { data } = await fetchRequest.promise;
            // Remove the finished promise from the set
            bikeAddRequests.current.delete(fetchRequest);
            // ID duplications should be handled on the backend, this should be safe
            setState({ bikes: [...bikes, data] });

            sortBikes();

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error creating bike', newBike, error);

                // Remove the finished promise from the set
                bikeAddRequests.current.delete(fetchRequest);
            }

            return null;
        }
    }

    async function deleteBike(id: string) {
        // deleteBike doesn't check if the bike exists in state
        // Just incase state is faulty

        // Check if bike is already being fetched
        const currentRequest = bikeDeleteRequests.current.get(id);
        if (currentRequest) {
            try {
                await currentRequest.promise;
                return true;
            } catch (error) {
                return false;
            }
        }

        const fetchRequest = makeCancellable(axios.delete(`${URL_BIKES}${id}/`));
        bikeDeleteRequests.current.set(id, fetchRequest);

        try {
            await fetchRequest.promise;
            // Remove the bike request from the list
            bikeDeleteRequests.current.delete(id);
            const newBikes = bikes.filter((itrBike: Bike) => itrBike.id != id);
            setState({ bikes: newBikes });

            sortBikes();

            return true;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error deleting bike', id, error);
                bikeDeleteRequests.current.delete(id);
            }

            return false;
        }
    }

    function deleteComponentFromBike(bikeID: string, componentID: string) {
        if (!componentsSet[bikeID] || !componentsSet[bikeID].length) return;

        setState({
            componentsSet: {
                ...componentsSet,
                [bikeID]: componentsSet[bikeID].filter(({ id }: { id: string}) => id !== componentID),
            },
        });
    }

    async function fetchBikeExample() {
        if (fetchBikeExampleRequest.current) {
            try {
                const data = await fetchBikeExampleRequest.current.promise;
                return data.default;
            } catch (error: any) {
                return null;
            }
        }

        fetchBikeExampleRequest.current = makeCancellable(import('../../assets/data/examples/bike.json'));
        try {
            const data = await fetchBikeExampleRequest.current.promise;

            fetchBikeExampleRequest.current = null;

            return data.default;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikeExampleRequest.current = null;
            }
            return null;
        }
    }

    async function fetchBike(uuid: string, useExisting = false) {
        if (!uuid) {
            return null;
        }

        if (uuid === EXAMPLE) {
            const bikeExample = await fetchBikeExample();

            return bikeExample;
        }
        if (useExisting) {
            // Check if the bike exists
            const bike = getBike(uuid);
            if (bike) {
                return bike;
            }
        }

        // Check if bike is already being fetched
        const currentRequest = bikeFetchRequests.current.get(uuid);
        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;
                return data;
            } catch (error: any) {
                return null;
            }
        }
        const fetchRequest = makeCancellable(axios.get(`${URL_BIKES}${uuid}/`));
        bikeFetchRequests.current.set(uuid, fetchRequest);
        try {
            const { data } = await fetchRequest.promise;
            bikeFetchRequests.current.delete(uuid);

            // don't update bike list is bike is archived
            if (!data.archived && (data.owner_id === nexus.nexusUserProfile.id)) {
                // Ensure no duplicates get added
                // eslint-disable-next-line eqeqeq
                setState({ bikes: [...bikes.filter((itrBike: Bike) => itrBike.id != uuid), data] });
            }

            sortBikes();

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching bike', uuid, error);
                bikeFetchRequests.current.delete(uuid);
            }

            return null;
        }
    }

    async function fetchBikeModelBrands() {
        if (fetchBikeModelBrandsRequest.current) {
            try {
                const { data } = await fetchBikeModelBrandsRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }

        fetchBikeModelBrandsRequest.current = makeCancellable(axios.get(
            `${URL_CLOUSEAU_API}brands/?udh_compliant=true&page_size=1000`,
        ));

        try {
            const { data } = await fetchBikeModelBrandsRequest.current.promise;
            fetchBikeModelBrandsRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching bike brands', error);
                fetchBikeModelBrandsRequest.current = null;
            }

            return null;
        }
    }

    async function fetchBikesByBrandId(brandId: string) {
        if (fetchBikesByBrandRequest.current) {
            try {
                const { data } = await fetchBikesByBrandRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }

        fetchBikesByBrandRequest.current = makeCancellable(
            axios.get(`${URL_CLOUSEAU_API}bikes/?brand=${brandId}&udh_compliant=true`),
        );

        try {
            const { data } = await fetchBikesByBrandRequest.current.promise;
            fetchBikesByBrandRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching bikes by brand', error);
                fetchBikesByBrandRequest.current = null;
            }

            return null;
        }
    }

    async function fetchBikeComponentSet(bikeUUID: string) {
        // fetch from the prev fetch data
        if (componentsSet[bikeUUID]) {
            return componentsSet[bikeUUID];
        }

        // fetch from the back-end
        let fetchRequest = fetchBikeComponentSetRequest.current.get(bikeUUID);
        if (fetchRequest) {
            try {
                const { data } = await fetchBikeComponentSetRequest.current.get(bikeUUID)?.promise;
                return data;
            } catch (err: any) {
                return null;
            }
        }

        fetchRequest = makeCancellable(axios.get(`${URL_BIKES}${bikeUUID}/components/`));
        fetchBikeComponentSetRequest.current.set(bikeUUID, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            fetchBikeComponentSetRequest.current.delete(bikeUUID);
            setState({ componentsSet: { ...componentsSet, [bikeUUID]: data } });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.error(error);
                fetchBikeComponentSetRequest.current.delete(bikeUUID);
            }

            return null;
        }
    }

    async function fetchBikeNotifications(bikeUUID: string) {
        if (!bikeUUID) return null;

        if (notificationSet[bikeUUID]) return notificationSet[bikeUUID];

        const currentRequest = fetchBikeNotificationSetRequest.current.get(bikeUUID);

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data;
            } catch (err: any) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.get(`${URL_BIKES}${bikeUUID}/notifications/`));
        fetchBikeNotificationSetRequest.current.set(bikeUUID, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            fetchBikeNotificationSetRequest.current.delete(bikeUUID);

            setState({ notificationSet: { ...notificationSet, [bikeUUID]: data } });

            return data;
        } catch (err: any) {
            fetchBikeNotificationSetRequest.current.delete(bikeUUID);

            return null;
        }
    }

    async function fetchDefaultCassettes() {
        fetchDefaultCassettesRequest.current = makeCancellable(import('../../assets/data/cassettes'));

        try {
            const data = await fetchDefaultCassettesRequest.current.promise;
            fetchDefaultCassettesRequest.current = null;
            setState({ cassettes: data.default });
            return data.default;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching default cassette list', error);
                fetchDefaultCassettesRequest.current = null;
            }
            return null;
        }
    }

    async function fetchCassettes() {
        if (fetchCassettesRequest.current) {
            try {
                const { data } = await fetchCassettesRequest.current.promise;
                return data;
            } catch (error: any) {
                return null;
            }
        }

        fetchCassettesRequest.current = makeCancellable(
            axios.get(`${URL_STATIC_DATA}cassette2cogs.json`),
        );

        try {
            const { data } = await fetchCassettesRequest.current.promise;

            fetchCassettesRequest.current = null;

            setState({ cassettes: data });
            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching cassette list', error);
                fetchCassettesRequest.current = null;
                const data = await fetchDefaultCassettes();
                return data;
            }

            return null;
        }
    }

    async function fetchDefaultChainrings() {
        fetchDefaultChainringsRequest.current = makeCancellable(import('../../assets/data/chainrings'));

        try {
            const data = await fetchDefaultChainringsRequest.current.promise;
            fetchDefaultChainringsRequest.current = null;

            setState({ chainrings: data.default });

            return data.default;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching default chainring list', error);
                fetchDefaultChainringsRequest.current = null;
            }

            return null;
        }
    }

    async function fetchChainrings() {
        if (fetchChainringsRequest.current) {
            try {
                const { data } = await fetchChainringsRequest.current.promise;
                return data;
            } catch (error: any) {
                return null;
            }
        }

        fetchChainringsRequest.current = makeCancellable(
            axios.get(`${URL_STATIC_DATA}chainrings2list.json`),
        );

        try {
            const { data } = await fetchChainringsRequest.current.promise;
            fetchChainringsRequest.current = null;

            setState({ chainrings: data });
            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching chainring list', error);
                fetchChainringsRequest.current = null;
                const data = await fetchDefaultChainrings();
                return data;
            }

            return null;
        }
    }

    async function fetchChainLengths(chainstayLength: number | null, maxCr: number) {
        if (fetchChainLengthRequest.current) {
            try {
                const { data } = await fetchChainLengthRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchChainLengthRequest.current = makeCancellable(axios.get(
            `${URL_CLOUSEAU}chainlength/?chainstay_length=${chainstayLength}&max_cr=${maxCr}`,
        ));

        try {
            const { data } = await fetchChainLengthRequest.current.promise;
            fetchChainLengthRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching chainLength', error);
                fetchChainLengthRequest.current = null;
            }

            return null;
        }
    }

    async function fetchChainLengthsByFrame(frame: any) {
        if (fetchChainLengthByFrameRequest.current) {
            try {
                const { data } = await fetchChainLengthByFrameRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchChainLengthByFrameRequest.current = makeCancellable(axios.get(
            `${URL_CLOUSEAU_API}frames/${frame}/chainlengths/`,
        ));

        try {
            const { data } = await fetchChainLengthByFrameRequest.current.promise;
            fetchChainLengthByFrameRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching chainLength', error);
                fetchChainLengthByFrameRequest.current = null;
            }

            return null;
        }
    }

    async function fetchOEMFrameCategories(category: any) {
        if (fetchFrameCategoriesRequest.current) {
            try {
                const { data } = await fetchFrameCategoriesRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchFrameCategoriesRequest.current = makeCancellable(axios.get(
            `${URL_CLOUSEAU_API}oemframecategories/${category}/`,
        ));

        try {
            const { data } = await fetchFrameCategoriesRequest.current.promise;
            fetchFrameCategoriesRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching OEM frame categories', error);
                fetchFrameCategoriesRequest.current = null;
            }

            return null;
        }
    }

    async function fetchUdhByFrame(frame: any) {
        if (fetchUdhByFrameRequest.current) {
            try {
                const { data } = await fetchUdhByFrameRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchUdhByFrameRequest.current = makeCancellable(axios.get(
            `${URL_CLOUSEAU_API}udh/?frame=${frame}`,
        ));

        try {
            const { data } = await fetchUdhByFrameRequest.current.promise;
            fetchUdhByFrameRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching UDH', error);
                fetchUdhByFrameRequest.current = null;
            }

            return null;
        }
    }

    async function fetchUdhByChainStayLength(chainStayLength: number) {
        if (fetchUdhByChainLengthRequest.current) {
            try {
                const { data } = await fetchUdhByChainLengthRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchUdhByChainLengthRequest.current = makeCancellable(axios.get(
            `${URL_CLOUSEAU_API}udh/?chainstay_length=${chainStayLength}`,
        ));

        try {
            const { data } = await fetchUdhByChainLengthRequest.current.promise;
            fetchUdhByChainLengthRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching UDH', error);
                fetchUdhByChainLengthRequest.current = null;
            }

            return null;
        }
    }

    async function updateBike(id: string, bikeUpdates: any) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        const hasImage = !!bikeUpdates.image;
        // Convert the data to a FormData object
        const bikeUpdatesFormData = serialiseBike(bikeUpdates);

        // Start the fetch request
        const fetchRequest = makeCancellable(
            axios.patch(
                `${URL_BIKES}${id}/`,
                hasImage ? bikeUpdatesFormData : bikeUpdates,
                hasImage ? { headers } : undefined,
            ),
        );

        // Add the cancellable promise to the set
        bikeUpdateRequests.current.add(fetchRequest);
        try {
            const { data } = await fetchRequest.promise;
            // Remove the finished promise from the set
            bikeUpdateRequests.current.delete(fetchRequest);
            // Override the bike with the updated bike
            setState({ bikes: bikes.map((bike:Bike) => ((bike.id === id) ? data : bike)) });

            sortBikes();

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Updating Bike', id, bikeUpdates, error);

                // Remove the finished promise from the set
                bikeUpdateRequests.current.delete(fetchRequest);
            }

            return null;
        }
    }

    useEffect(() => {
        if (!auth.accessToken) {
            clear();
            return;
        }
        fetchBikes();
    }, [auth.accessToken]);

    useEffect(() => {
        fetchCassettes();
        fetchChainrings();
    }, []);

    useEffect(() => () => {
        clear();
    }, []);

    return (
        <BikesContext.Provider
            value={{
                bikes: {
                    ...state,
                    addBike,
                    clear,
                    deleteBike,
                    deleteComponentFromBike,
                    fetch: () => fetchBikes(),
                    fetchBike,
                    fetchBikeComponentSet,
                    fetchBikeModelBrands,
                    fetchBikeNotifications,
                    fetchBikesByBrandId,
                    fetchCassettes,
                    fetchChainLengths,
                    fetchChainLengthsByFrame,
                    fetchChainrings,
                    fetchOEMFrameCategories,
                    fetchUdhByChainStayLength,
                    fetchUdhByFrame,
                    list: bikes,
                    updateBike,
                },
            }}
        >
            {children}
        </BikesContext.Provider>
    );
}

export default BikesProvider;
