import MomentUtils from '@date-io/moment';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { useState } from 'react';
import Moment from 'react-moment';

import styles from './DateRangePicker.module.scss';

import Translate from '../translate';

import { SRAM_RED } from '../../constants';

const materialTheme = createTheme({
    palette: { primary: { main: SRAM_RED } },
});
// @ts-ignore
const USER_LOCALE = navigator.language || navigator.userLanguage;
const LOCALE_DATE_FORMATE = USER_LOCALE ? (moment.localeData(USER_LOCALE).longDateFormat('L')) : 'DD/MM/YYYY';

interface DateRangePickerProps{
    className?: string,
    end: Date | Moment | MaterialUiPickersDate | null,
    hideClear?: boolean,
    onSave: (object:Record<string, Moment | MaterialUiPickersDate | null>) => void,
    start: Date | Moment | MaterialUiPickersDate | null,
}

const DateRangePicker = ({
    className = '',
    end = new Date(),
    hideClear = false,
    onSave = () => { },
    start = null,
}: DateRangePickerProps) => {
    const [stagedEnd, setStagedEnd] = useState(end);
    const [stagedStart, setStagedStart] = useState(start);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={USER_LOCALE}>
            <ThemeProvider theme={materialTheme}>
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${className}`}>
                            <Translate>FROM</Translate>
                        </div>
                        <DatePicker
                            clearable
                            disableFuture
                            emptyLabel={LOCALE_DATE_FORMATE}
                            format={LOCALE_DATE_FORMATE}
                            onAccept={(date) => onSave({ from: date })}
                            onChange={(newDate) => setStagedStart(newDate)}
                            value={stagedStart}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${className}`}>
                            <Translate>TO</Translate>
                        </div>
                        <DatePicker
                            clearable
                            disableFuture
                            emptyLabel={LOCALE_DATE_FORMATE}
                            format={LOCALE_DATE_FORMATE}
                            onAccept={(date) => onSave({ to: date })}
                            onChange={(newDate) => setStagedEnd(newDate)}
                            value={stagedEnd}
                        />
                    </div>
                    {!hideClear && (
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={`${styles.clearButton} ${(end || start) ? styles.active : ''}`}
                                onClick={() => {
                                    setStagedEnd(null);
                                    setStagedStart(null);
                                    onSave({ from: null, to: null });
                                }}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    )}
                </div>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default DateRangePicker;
