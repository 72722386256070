import { Cookies } from 'react-cookie-consent';
import React, { useEffect, useRef, useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import styles from './CustomBike.module.scss';

import EagleChainLengthModal from '../../eagleChainLengthModal';
import { imageHowToMeasure } from '../../../../assets';
import Button from '../../../../components/button';
import ContentContainer from '../../../../components/contentContainer';
import Modal from '../../../../components/modal';
import ToggleSelector from '../../../../components/toggleSelector';
import Translate, { useTranslation } from '../../../../components/translate';
import Spinner from '../../../../components/spinner';
import TranslateOption from '../../../../components/translateOption';
import {
    AXS_TEXT_GREY,
    CHAINRING_SIZES,
    CHAINSTAY_LENGTH,
    makeCancellable,
    RequestType,
    SRAM_100,
} from '../../../../constants';
import Logger from '../../../../Logger';
import { useBikes } from '../../../../providers/bikes';
import { useAuth } from '../../../../providers/auth/AuthContext';
import FirebaseAnalytics from '../../../../components/firebaseAnalytics';
import { checkCookieConsent } from '../../../../components/analytics/Analytics';

interface CustomBikeProps {
    isOpen: boolean,
    onBack: () => void,
     }

const CustomBike = ({ isOpen = false, onBack }: CustomBikeProps) => {
    const { isAuthenticated } = useAuth();
    const cookieConsent = Cookies.get('CookieConsent');
    const [supportedChainRings, setSupportedChainRings] = useState<[]>([]);
    const [chainLength, setChainLength] = useState< null | number>(null);
    const [chainStayLength, setChainStayLength] = useState<number | null>(null);
    const [idlerPulley, setIdlerPulley] = useState<boolean>(false);
    const [isCalculating, setIsCalculating] = useState<boolean>(false);
    const [openChainLengthModal, setOpenChainLengthModal] = useState<boolean>(false);
    const [openNoticeModal, setOpenNoticeModal] = useState<boolean>(false);
    const [openSetUpInfoModal, setOpenSetUpInfoModal] = useState<boolean>(false);
    const [selectedFrameType, setSelectedFrameType] = useState<string>('hardtail');
    const [selectedChainringSize, setSelectedChainringSize] = useState<string>('');
    const [categoryInfo, setCategoryInfo] = useState<any>(null);
    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);

    const bikes = useBikes();
    const translate = useTranslation();

    useEffect(() => {
        setChainStayLength(null);
        setOpenChainLengthModal(false);
        setSelectedFrameType('hardtail');
        setSelectedChainringSize('');
        setCategoryInfo(null);

        return (() => {
            if (fetchChainLengthRequest.current) {
                fetchChainLengthRequest.current.cancel();
                fetchChainLengthRequest.current = null;
            }

            if (fetchFrameCategoriesRequest.current) {
                fetchFrameCategoriesRequest.current.cancel();
                fetchFrameCategoriesRequest.current = null;
            }
        });
    }, [isOpen]);

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            ChainStayLength: chainStayLength,
            CustomChainringSize: selectedChainringSize,
            FrameType: selectedFrameType,
        };

        const properties = {
            ...eventFields,
            action: 'Custom Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Custom_Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Custom_Chainlength_Calculation', properties);
        }

        Logger.log('CustomChainLengthFormFields', eventFields);
    }

    async function fetchOEMFrameCategories(category: number) {
        if (!category) return;

        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data) {
                setCategoryInfo(data);
                setOpenChainLengthModal(true);
                setIsCalculating(false);

                if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchFrameCategoriesRequest.current = null;
                setIsCalculating(false);
            }
        }
    }

    async function fetchChainLengths() {
        setIsCalculating(true);
        fetchChainLengthRequest.current = makeCancellable(bikes.fetchChainLengths(
            chainStayLength,
            CHAINRING_SIZES[CHAINRING_SIZES.length - 1].value,
        ));

        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            const selectedChainLength = data[selectedChainringSize.split('T')[0]];

            const supportedChainrings: any = [];
            Object.entries(data).forEach(([key, value]) => {
                if (value === selectedChainLength) {
                    supportedChainrings.push(`${key}T`);
                }
            });
            if (!data) return;

            if (selectedChainringSize) {
                setSupportedChainRings(supportedChainrings);
                setChainLength(data[selectedChainringSize.split('T')[0]]);
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchChainLengthRequest.current = null;
            }
        }
    }

    async function calculateChainLength() {
        if (!chainStayLength) return;

        if (selectedFrameType !== 'hardtail' && idlerPulley) {
            setOpenNoticeModal(true);
            return;
        }

        fetchChainLengths();

        const category = 1;
        fetchOEMFrameCategories(category);
    }

    function renderIdlerPulleyNoticeModal() {
        return (
            <Modal
                className={styles.noticeModalContainer}
                contentClassName={styles.infoContent}
                contentLabel="Idler Pulley Notice"
                isOpen={openNoticeModal}
                header="NOTICE"
                hideCloseButton
                hideImage
            >
                <div className={styles.noticeContainer}>
                    <Translate>IDLER_PULLEY_NOTICE</Translate>
                    <div className={styles.buttonContainer}>
                        <a
                            className={styles.link}
                            // eslint-disable-next-line max-len
                            href="https://docs.sram.com/en-US/publications/5jblJ4SRpeHwjcuWG1vPy4#hashItem=idler-pulley-frame-setup-information"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Button
                                color={AXS_TEXT_GREY}
                                inverse
                                onClick={() => setOpenNoticeModal(false)}
                            >
                                <Translate>VIEW_PRODUCT_MANUAL</Translate>
                            </Button>
                        </a>
                        <Button onClick={() => {
                            setIdlerPulley(false);
                            setOpenNoticeModal(false);
                        }}
                        >
                            <Translate>CLOSE</Translate>
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    function renderInfoModal() {
        return (
            <Modal
                className={styles.modalContainer}
                contentClassName={styles.infoContent}
                contentLabel="InfoModal"
                isOpen={openSetUpInfoModal}
                header="HOW_TO_MEASURE"
                hideImage
                onClose={() => setOpenSetUpInfoModal(false)}
            >
                <div className={styles.setupGuideContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.infoSubHeader}>
                            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH_TITLE</Translate>
                        </div>
                        <div className={styles.infoDetail}>
                            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH</Translate>
                        </div>
                        <div className={styles.infoSubHeader}>
                            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE_TITLE</Translate>
                        </div>
                        <div className={styles.infoDetail}>
                            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE</Translate>
                        </div>
                    </div>
                    <div className={styles.imageContainer}>
                        <img
                            alt="Setup Guide"
                            className={styles.image}
                            src={imageHowToMeasure}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    if (!isOpen) {
        return null;
    }

    return (
        <ContentContainer className={styles.container}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    calculateChainLength();
                }}
            >
                { isCalculating && <Spinner />}
                <button
                    className={styles.backButton}
                    onClick={onBack}
                    type="button"
                >
                &lt;&nbsp;
                    <Translate>BACK</Translate>
                </button>
                <div className={styles.customBike}>
                    <Translate>BIKE_NOT_LISTED</Translate>
                </div>
                <div className={styles.customBikeDescription}>
                    <Translate>CUSTOM_BIKE_INFO</Translate>
                </div>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Translate>CHAINSTAY_LENGTH</Translate>
                            <button
                                className={styles.helpButton}
                                onClick={() => setOpenSetUpInfoModal(true)}
                                id="infoButton"
                                type="button"
                            >
                                <InfoOutlinedIcon />
                            </button>
                        </div>
                        <select
                            className={styles.inputField}
                            onChange={(event) => setChainStayLength(Number(event.target.value))}
                            value={chainStayLength || ''}
                        >
                            <TranslateOption>SELECT</TranslateOption>
                            {CHAINSTAY_LENGTH.map(({ label, value }) => (
                                <option key={label} value={value}>{label}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Translate>CHAINRING_SIZE</Translate>
                            <button
                                className={styles.helpButton}
                                onClick={() => setOpenSetUpInfoModal(true)}
                                id="infoButton"
                                type="button"
                            >
                                <InfoOutlinedIcon />
                            </button>
                        </div>
                        <select
                            className={styles.inputField}
                            onChange={(event) => setSelectedChainringSize(event.target.value)}
                            value={selectedChainringSize}
                        >
                            <TranslateOption>SELECT</TranslateOption>
                            {CHAINRING_SIZES.map(({ label }) => (
                                <option key={label} value={label}>{label}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Translate>FRAME_TYPE</Translate>
                        </div>
                        <ToggleSelector
                            activeButton={selectedFrameType}
                            buttonClassName={styles.toggleButton}
                            buttons={[
                                { label: translate('HARDTAIL'), value: 'hardtail' },
                                { label: translate('FULL_SUSPENSION'), value: 'fullSuspension' },
                            ]}
                            color={SRAM_100}
                            className={styles.toggler}
                            onToggle={(value) => setSelectedFrameType(value)}
                        />
                    </div>
                    { selectedFrameType === 'fullSuspension' && (
                        <div className={styles.row}>
                            <div className={styles.label}>
                                <Translate>IDLER_PULLEY</Translate>
                            </div>
                            <ToggleSelector
                                activeButton={idlerPulley}
                                buttonClassName={styles.toggleButton}
                                buttons={[
                                    { label: translate('YES'), value: true },
                                    { label: translate('NO'), value: false },
                                ]}
                                color={SRAM_100}
                                className={styles.toggler}
                                onToggle={(value) => setIdlerPulley(value)}
                            />
                        </div>
                    )}
                </div>
                {renderInfoModal()}
                {renderIdlerPulleyNoticeModal()}
                <EagleChainLengthModal
                    bike={selectedFrameType === 'hardtail' ? translate('HARDTAIL') : translate('FULL_SUSPENSION')}
                    chainLength={chainLength}
                    chainStayLength={chainStayLength}
                    isOpen={openChainLengthModal}
                    onClose={() => setOpenChainLengthModal(false)}
                    setUpCogPosition={categoryInfo && categoryInfo.setup_cog_position}
                    setUpCogPositionText={categoryInfo && categoryInfo.setup_cog_position_text}
                    setUpCogImage={categoryInfo && categoryInfo.setup_cog_position_image}
                    setUpKeyPos={categoryInfo && categoryInfo.setup_key_position_text}
                    setUpKeyPosImage={categoryInfo && categoryInfo.setup_key_position_image}
                    supportedChainrings={supportedChainRings}
                />
                {(chainStayLength && selectedChainringSize) && (
                    <div className={styles.footer}>
                        <button
                            className={styles.nextButton}
                            type="submit"
                        >
                            <Translate>SUBMIT</Translate>
                        </button>
                    </div>
                )}
            </form>
        </ContentContainer>
    );
};

export default CustomBike;
