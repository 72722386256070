import WarningIcon from '@material-ui/icons/Warning';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AutoSizer } from 'react-virtualized';

import styles from './XPLRChainLengthModal.module.scss';

import MobileContainer from '../../../components/mobileContainer';
import Modal from '../../../components/modal';
import Translate, { useTranslation } from '../../../components/translate';
import { XPLR_SUPPORT_VIDEOS } from '../../../constants';

interface videoType {
    name: string,
    url: string,
}

const VideoItem = ({ index, style, data }: ListChildComponentProps<videoType[]>) => {
    const item = data[index];
    const id = item.url?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)?.pop();

    return (
        <a
            style={{ ...style, height: '240px', width: '240px' }}
            href={item.url}
            key={item.url}
            rel="noreferrer"
            target="_blank"
        >
            <div className={styles.videoImageContainer}>
                <img
                    alt=""
                    className={styles.videoImage}
                    src={`https://img.youtube.com/vi/${id}/0.jpg`}
                />
            </div>
            <div className={styles.videoTitle}>
                <Translate>{item.name}</Translate>
            </div>
        </a>
    );
};

export interface XPLRChainLengthModalProps {
    bike: string,
    chainLength: number | null,
    chainStayLength: number | null,
    chainRingSize: string,
    isOpen: boolean,
    onClose: () => void,
    setUpCogPosition: string,
    setUpCogPositionText: string,
    supportedChainrings: Array<string>,
}

const XPLRChainLengthModal = ({
    bike,
    chainLength,
    chainStayLength,
    chainRingSize,
    isOpen,
    onClose,
    setUpCogPosition,
    setUpCogPositionText,
    supportedChainrings,
}: XPLRChainLengthModalProps) => {
    if (!isOpen || !chainStayLength || !chainLength || !setUpCogPosition) {
        return null;
    }

    const translate = useTranslation();

    return (
        <Modal
            className={styles.modalContainer}
            contentClassName={styles.content}
            contentLabel=""
            hideImage
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className={styles.container}>
                <div className={styles.info}>
                    <div className={styles.chainLengthInfo}>
                        <div className={styles.header}>
                            <div className={styles.selectedBike}>
                                <Translate>BIKE</Translate>
                            : &nbsp;
                                {bike}
                            </div>
                            <div className={styles.selectedChainstay}>
                                <Translate>CHAINSTAY_LENGTH</Translate>
                            : &nbsp;
                                {chainStayLength}
                            </div>
                            <div className={styles.selectedChainstay}>
                                <Translate>CHAINRING_SIZE</Translate>
                            : &nbsp;
                                {chainRingSize}
                            </div>
                        </div>
                        <div className={styles.chainLengthResult}>

                            <div
                                className={styles.row}
                                key="chain-length"
                            >
                                <div className={styles.label}>
                                    <Translate>CHAIN_LENGTH</Translate>
                                </div>
                                <div className={styles.value}>
                                    {chainLength}
                                &nbsp;
                                    <Translate>LINKS</Translate>
                                </div>
                            </div>
                            <div
                                className={styles.row}
                                key="supports-chainrings"
                            >
                                <div className={styles.label}>
                                    <Translate>SUPPORTS_CHAINRINGS</Translate>
                                </div>
                                <div className={styles.value}>
                                    {supportedChainrings.map((chainringSize, index) => {
                                        if (index === 0) {
                                            return `${chainringSize}`;
                                        }
                                        return ` & ${chainringSize}`;
                                    })}
                                </div>
                            </div>
                            <div
                                className={styles.row}
                                key="setup-cog"
                            >
                                <div className={styles.label}>
                                    <Translate>SETUP_COG</Translate>
                                </div>
                                <div className={styles.value}>
                                    {`${setUpCogPositionText} (${setUpCogPosition})`}
                                </div>
                            </div>
                        </div>
                        <a className={styles.link}
                            href="https://docs.sram.com/en-US/publications/6TmfV97fHWv8kvGXVegoTy"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <Translate>XPLR_USER_MANUAL</Translate>
                        </a>
                        <MobileContainer className={styles.footer}>
                            <div className={styles.warningSign}>
                                <WarningIcon />
                                <Translate>WARNING</Translate>
                                <WarningIcon />
                            </div>
                            <div className={styles.warningWrapper}>
                                {
                                    bike === translate('FULL_SUSPENSION') && (
                                        <div className={styles.warningBold}>
                                            <Translate>WARNING_INFO_FULL_SAG</Translate>
                                        </div>
                                    )
                                }
                                <br />
                                <div className={styles.warning}>
                                    <Translate>WARNING_INFO</Translate>
                                </div>
                            </div>
                        </MobileContainer>
                    </div>
                </div>
            </div>
            <div className={styles.videoContainerLabel}>
                <Translate>SUPPORT_VIDEOS</Translate>
            </div>
            <AutoSizer disableHeight>
                {({ width }) => (
                    <FixedSizeList
                        height={250}
                        width={width}
                        itemCount={XPLR_SUPPORT_VIDEOS.length}
                        itemData={XPLR_SUPPORT_VIDEOS}
                        itemSize={250}
                        layout="horizontal"

                    >
                        {VideoItem}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </Modal>
    );
};

export default XPLRChainLengthModal;
