import { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Moment } from 'moment';
import styles from './ActivitiesFilter.module.scss';
import DistanceDurationSliders from './distanceDurationSliders';
import FilterDropdown from './filterDropdown';

import ActivitiesFilterModal from '../activitiesFilterModal';

import CheckboxForm from '../../../views/checkboxForm';
import DateRangePicker from '../../../components/dateRangePicker';
import DesktopContainer from '../../../components/desktopContainer';
import MobileContainer from '../../../components/mobileContainer';
import Translate from '../../../components/translate';

import { iconFilter } from '../../../assets';
import { useActivities, useBikes } from '../../../providers';
import { useSetState } from '../../../hooks';

const MAX_DISTANCE = 200;
const MAX_DURATION = 24;
const MIN_DISTANCE = 0;
const MIN_DURATION = 0;
const DEFAULT_STATE = {
    distance: [MIN_DISTANCE, MAX_DISTANCE],
    duration: [MIN_DURATION, MAX_DURATION],
    filterActivityTypeOpen: false,
    filterBikeOpen: false,
    filterDateOpen: false,
    filterDistanceDurationOpen: false,
    from: null,
    to: null,
};

interface ActivitiesFilterProps {
    activeActivityFilters: string[];
    activeBikeFilters: string[] | number[];
    applyFilters: (value: {[key:string]: number[] | string} | {[key:string]: (string | number)[]}) => void
    distanceFilters: number[];
    durationFilters: number[];
    fromDate: Date | Moment | MaterialUiPickersDate | null;
    toDate: Date | Moment | MaterialUiPickersDate | null;
}

const ActivitiesFilter = ({
    activeActivityFilters,
    activeBikeFilters,
    applyFilters,
    distanceFilters,
    durationFilters,
    fromDate,
    toDate,
}: ActivitiesFilterProps) => {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const activities = useActivities();
    const bikes = useBikes();

    const bikesList = bikes.list.map((bike) => ({ label: bike.name, value: bike.id }));

    const {
        distance,
        duration,
        filterActivityTypeOpen,
        filterBikeOpen,
        filterDateOpen,
        filterDistanceDurationOpen,
        from,
        to,
    } = state;

    useEffect(() => setState({ from: fromDate, to: toDate }), [fromDate, toDate]);

    useEffect(() => setState({ distance: distanceFilters }), [distanceFilters]);

    useEffect(() => setState({ duration: durationFilters }), [durationFilters]);

    const toggleFilterDropdown = (key: string) => {
        const { [key]: dropdownFilter } = state;

        const dropdownKeys = [
            'filterActivityTypeOpen',
            'filterBikeOpen',
            'filterDateOpen',
            'filterDistanceDurationOpen',
        ];

        delete dropdownKeys[key as keyof typeof dropdownKeys];

        const newState : Record<string, any> = {};
        newState[key] = !dropdownFilter;

        dropdownKeys.forEach((dropdownKey) => {
            const { [dropdownKey]: dropdown } = state;

            if (dropdown) {
                newState[dropdownKey] = false;
            }
        });

        setState(newState);
    };

    const showFilterModal = () => [
        filterActivityTypeOpen,
        filterBikeOpen,
        filterDateOpen,
        filterDistanceDurationOpen,
    ].some((filterStatus) => filterStatus);

    const toggleOffFilterModal = () => {
        setState({
            filterActivityTypeOpen: false,
            filterBikeOpen: false,
            filterDateOpen: false,
            filterDistanceDurationOpen: false,
        });
    };

    const applyDateFilters = () => {
        const newFromDate = (fromDate !== from);
        const newToDate = (toDate !== to);

        if (newFromDate || newToDate) {
            applyFilters({
                ...(newFromDate ? { fromDate: from } : {}),
                ...(newToDate ? { toDate: to } : {}),
            });
        }
    };

    const applyDistanceDurationFilters = () => {
        const newDistance = JSON.stringify(distanceFilters) !== JSON.stringify(distance);
        const newDuration = JSON.stringify(durationFilters) !== JSON.stringify(duration);

        // trigger apply filters only if there are changes detected
        if (newDistance || newDuration) {
            applyFilters({
                ...(newDistance ? { distanceFilters: distance } : {}),
                ...(newDuration ? { durationFilters: duration } : {}),
            });
        }
    };

    const activeDistanceDurationFilters = () => {
        const [minDistance, maxDistance] = distanceFilters;
        const [minDuration, maxDuration] = durationFilters;

        return (
            (minDistance !== MIN_DURATION)
            || (maxDistance !== MAX_DISTANCE)
            || (minDuration !== MIN_DURATION)
            || (maxDuration !== MAX_DURATION)
        );
    };

    const toggleDistanceDuration = () => {
        if (filterDistanceDurationOpen) {
            // apply filters if filter dropdown is to be toggled off
            applyDistanceDurationFilters();
        }

        toggleFilterDropdown('filterDistanceDurationOpen');
    };

    const toggleDate = () => {
        if (filterDateOpen) {
            applyDateFilters();
        }

        toggleFilterDropdown('filterDateOpen');
    };

    return (
        <>
            <DesktopContainer>
                <div className={styles.filterOptionsBadge}>
                    <FilterDropdown
                        active={activeActivityFilters.length}
                        isOpen={filterActivityTypeOpen}
                        label="ACTIVITY_TYPE"
                        onClick={() => toggleFilterDropdown('filterActivityTypeOpen')}
                        title="ACTIVITY_TYPE"
                    >
                        <CheckboxForm
                            checked={activeActivityFilters}
                            onChange={(filter : (string | number)[]) => applyFilters({ typeFilters: filter })}
                            options={activities.activityTypes}
                        />
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={`${styles.clearButton}`}
                                onClick={() => applyFilters({ typeFilters: [] })}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    </FilterDropdown>
                    <FilterDropdown
                        active={activeBikeFilters.length}
                        isOpen={filterBikeOpen}
                        label="BIKES"
                        onClick={() => toggleFilterDropdown('filterBikeOpen')}
                        title="BIKES"
                    >
                        <CheckboxForm
                            checked={activeBikeFilters}
                            onChange={(selectedBikes: (string | number)[]) => {
                                applyFilters({ bikeFilters: selectedBikes });
                            }}
                            options={bikesList}
                        />
                        {!bikesList.length && (
                            <div>
                                <div style={{ padding: '0.5rem 1rem' }}>
                                    <Translate>NO_BIKES</Translate>
                                </div>
                                <div style={{ padding: '0.5rem 1rem' }}>
                                    <Link className={styles.createBike} to="/buildbike">
                                        <Translate>BIKE_ADD_NEW</Translate>
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={`${styles.clearButton}`}
                                onClick={() => applyFilters({ bikeFilters: [] })}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    </FilterDropdown>
                    <FilterDropdown
                        active={activeDistanceDurationFilters()}
                        isOpen={filterDistanceDurationOpen}
                        onClick={() => toggleDistanceDuration()}
                        title="DISTANCE_OR_DURATION"
                    >
                        <DistanceDurationSliders
                            distance={distanceFilters}
                            duration={durationFilters}
                            maxDistance={MAX_DISTANCE}
                            maxDuration={MAX_DURATION}
                            minDistance={MIN_DISTANCE}
                            minDuration={MIN_DURATION}
                            onChange={(stagedUpdates) => setState({ ...stagedUpdates })}
                        />
                    </FilterDropdown>
                    <FilterDropdown
                        active={toDate || fromDate}
                        isOpen={filterDateOpen}
                        label="DATE_RANGE"
                        onClick={() => toggleDate()}
                        title="DATE"
                    >
                        <div className={styles.dateFilterDropdown}>
                            <DateRangePicker
                                className={styles.DateRangePicker}
                                end={toDate}
                                start={fromDate}
                                onSave={(newDateRange) => setState(newDateRange)} hideClear={false}
                            />
                        </div>
                    </FilterDropdown>
                </div>
            </DesktopContainer>
            <MobileContainer>
                <div className={styles.filterOptions}>
                    <button
                        className={styles.filterButtons}
                        onClick={() => toggleFilterDropdown('filterActivityTypeOpen')}
                        type="button"
                    >
                        <img alt="" className={styles.buttonIcon} src={iconFilter} />
                        <Translate>FILTER</Translate>
                    </button>
                </div>
            </MobileContainer>
            <ActivitiesFilterModal
                activityTypes={activities.activityTypes}
                applyFilters={applyFilters}
                distanceFilters={distanceFilters}
                durationFilters={durationFilters}
                fromDate={fromDate}
                isOpen={showFilterModal()}
                onCancel={() => toggleOffFilterModal()}
                selectedBikesFilter={activeBikeFilters}
                selectedTypeFilter={activeActivityFilters}
                toDate={toDate}
            />
        </>
    );
};

export default ActivitiesFilter;
