import Refresh from '@material-ui/icons/Refresh';
import React, { ReactNode, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import styles from './SegmentTools.module.scss';

import { iconRightArrow } from '../../../../assets';
import CollapsablePanel from '../../../../components/collapsablePanel';
import Translate from '../../../../components/translate';
import { Activity } from '../../../../providers';

interface SegmentToolsProps {
    activity: Activity | null,
    children: ReactNode,
    disableTrim: boolean,
    endTs: number,
    isOwner: boolean,
    onToggleTrimActivity: () => void,
    startTs: number | null,
}

const SegmentTools = ({
    activity = null,
    children = null,
    disableTrim = false,
    endTs,
    isOwner = false,
    onToggleTrimActivity = () => {},
    startTs = null,
}: SegmentToolsProps) => {
    const location = useLocation();
    const params = useParams();
    const search = new URLSearchParams(location.search);
    const locationEndTs = search.get('end_ts');
    const locationStartTs = search.get('start_ts');

    const segmentIsActive = !!(locationStartTs || locationEndTs);

    const [isOpen, setIsOpen] = useState(segmentIsActive);

    if (!activity?.activitysummary_set || !activity.activitysummary_set.length) return null;

    const hasTrim = (
        activity
        && !!(activity.activitysummary_set[0].data.trim_end_ts || activity.activitysummary_set[0].data.trim_start_ts)
    );

    const hasNewStart = activity && startTs !== activity.adjustedStartTs;
    const hasNewEnd = activity && endTs !== activity.adjustedEndTs;
    const disableTrimButton = ((!segmentIsActive && !hasNewEnd && !hasNewStart) || disableTrim) || !isOwner;

    return (
        <>
            <CSSTransition
                appear
                classNames={{
                    appear: styles.resetButtonFloatingOutsideViewPort,
                    enter: styles.resetButtonFloatingOutsideViewPort,
                    exit: styles.resetButtonFloatingOutsideViewPort,
                }}
                exit
                in={segmentIsActive}
                timeout={200}
                unmountOnExit
            >
                <Link
                    className={styles.resetButtonFloating}
                    to={`/activities/${params.id}`}
                >
                    <Refresh className={styles.refreshIcon} />
                </Link>
            </CSSTransition>

            <CollapsablePanel
                buttonClassName={styles.collapsablePanelButton}
                containerClassName={styles.container}
                label={(
                    <>
                        <Translate>SELECTION_TOOLS</Translate>
                        <img
                            alt=""
                            className={`${styles.indicator} ${!isOpen ? styles.rotateIcon : ''}`}
                            src={iconRightArrow}
                        />
                    </>
                )}
                onToggle={() => setIsOpen(!isOpen)}
                openOnMount={segmentIsActive}
            >
                <div className={styles.subtitle}>
                    <Translate>SELECTION_TOOLS_INSTRUCTIONS</Translate>
                </div>
                {children}
                <div className="flex">
                    <button
                        className={`${styles.trimButton} ${disableTrimButton ? styles.trimButtonDisabled : ''}`}
                        disabled={disableTrimButton}
                        onClick={onToggleTrimActivity}
                        type="button"
                    >
                        <Translate>{(!segmentIsActive && hasTrim) ? 'RESTORE' : 'TRIM' }</Translate>
                    </button>
                    <Link
                        className={`${styles.resetButton} ${!segmentIsActive ? styles.resetButtonDisabled : ''}`}
                        to={`/activities/${params.id}`}
                    >
                        <Refresh className={styles.refreshIcon} />
                        &nbsp;
                        <Translate>RESET</Translate>
                    </Link>
                </div>
            </CollapsablePanel>
        </>
    );
};

export default SegmentTools;
