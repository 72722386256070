import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Moment from 'react-moment';
import { useAuth, useNexus } from '../../providers';
import Translate from '../../components/translate';
import ContentContainer from '../../components/contentContainer';
import styles from './mobileRiderPortal.module.scss';
import MobileContainer from '../../components/mobileContainer';
import { MenuItemTypes } from './types';
import ExternalServices from './externalServices/ExternalServices';

const MobileRiderPortal = () => {
    const auth = useAuth();
    const nexus = useNexus();
    const navigate = useNavigate();

    const renderHeader = () => (
        <>
            <MobileContainer className={styles.header}>
                <div className={styles.title}>
                    <Translate>ACCOUNT</Translate>
                </div>
            </MobileContainer>
        </>
    );

    const renderRow = ({
        title, onClick, value, disabled,
    }: MenuItemTypes) => (
        <div
            role="button"
            className={styles.row}
            tabIndex={0}
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && !disabled) {
                    onClick();
                }
            }}
        >
            <div className={styles.rowTitle}>
                <Translate>{title}</Translate>
            </div>
            <div className={styles.rowEnd}>
                {title === 'MOBILE_JOINED' ? (
                    <Moment format="MMMM Do, YYYY" unix>
                        {nexus.nexusUserProfile.create_ts}
                    </Moment>
                )
                    : (
                        <>
                            <div className={styles.value}>
                                <Translate>{value && value}</Translate>
                            </div>
                            <div>
                                <ArrowForwardIosIcon />
                            </div>
                        </>
                    )}
            </div>

        </div>
    );

    const renderMenuItems = (sectionHeader: string, items: any[]) => (
        <>
            <MobileContainer key={sectionHeader} className={styles.section}>
                <div className={styles.sectionTitle}>
                    <Translate>{sectionHeader}</Translate>
                </div>
                {items.map((item) => renderRow(item))}
                <div />
            </MobileContainer>
        </>
    );

    const manageAccount = [
        {
            disabled: false,
            onClick: () => navigate('/mobile/riderportal/updateaccount'),
            title: 'MOBILE_UPDATE_ACCOUNT',
            value: nexus.nexusUserProfile.email,
        },
        {
            disabled: false,
            onClick: () => navigate('/mobile/riderportal/communications'),
            title: 'MOBILE_COMMUNICATIONS',
            value: '',
        },
        {
            disabled: false,
            onClick: () => {},
            title: 'MOBILE_JOINED',
            value: nexus.nexusUserProfile.create_ts,
        },

    ];

    return (
        <>
            <Helmet>
                <title>Mobile Rider Portal</title>
                <meta
                    name="Mobile Rider Portal"
                    content="C"
                />
            </Helmet>
            <ContentContainer className={styles.container}>
                {renderHeader()}
                {renderMenuItems('MOBILE_MANAGE_ACCOUNT', manageAccount)}
                <ExternalServices />
            </ContentContainer>
        </>
    );
};

export default MobileRiderPortal;
