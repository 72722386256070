import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import { bool, func } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './BikeRackDropdown.module.scss';
import MenuItem from './menuItem';
import MenuItemButton from './menuItemButton';
import NavBarDropdown from './navBarDropdown';

import BikeAlertBadge from '../bikeAlertBadge';

import Translate from '../../components/translate';
import { SRAM_RED, WHITE } from '../../constants';
import { useBikes } from '../../providers/bikes/BikesContext';

interface BikeRackDropdownProps {
    onAuxClick?: () => void,
    onClick: () => void,
    open: boolean,
}

const BikeRackDropdown = ({
    onAuxClick,
    onClick,
    open,
}: BikeRackDropdownProps) => {
    const bikes = useBikes();
    const location = useLocation();
    return (
        <NavBarDropdown
            buttonClassName={styles.buttonHover}
            containerStyle={{ padding: '0 1rem' }}
            dropdownStyle={{ left: '50%', transform: 'translateX(-50%)' }}
            label={(
                <DirectionsBikeIcon fontSize="large" style={{ color: WHITE }} />
            )}
            onAuxClick={onAuxClick}
            onClick={onClick}
            open={open}
        >
            <MenuItem className={styles.menuItem}>
                <MenuItemButton
                    onClick={onClick}
                    style={(location.pathname === '/bikerack') ? { color: SRAM_RED } : {}}
                    to="/bikerack"
                >
                    <Translate>MY_BIKES</Translate>
                </MenuItemButton>
            </MenuItem>
            <MenuItem className={styles.menuItem}>
                <MenuItemButton
                    onClick={onClick}
                    style={(location.pathname === '/components') ? { color: SRAM_RED } : {}}
                    to="/components"
                >
                    <Translate>MY_COMPONENTS</Translate>
                </MenuItemButton>
            </MenuItem>
            {(bikes.list
                .filter((bike) => !bike.data || !bike.data.retired)
                .map((bike) => (
                    <MenuItem className={styles.menuItem} key={bike.uuid}>
                        <MenuItemButton
                            className={`${styles.menuItem} ${styles.menuItemButton}`}
                            onClick={onClick}
                            style={(location.pathname === `/bikerack/${bike.uuid}`) ? { color: SRAM_RED } : {}}
                            to={`/bikerack/${bike.id}`}
                        >
                            <div>{bike.name}</div>
                            <div className={styles.alertBadgeContainer}>
                                <BikeAlertBadge className={styles.alertBadgeImage} />
                            </div>
                        </MenuItemButton>
                    </MenuItem>
                ))
            )}
            <MenuItem className={styles.menuItem}>
                <MenuItemButton
                    className={`${styles.menuItem} ${styles.menuItemButton}`}
                    onClick={onClick}
                    style={(location.pathname === '/buildbike') ? { color: SRAM_RED } : {}}
                    to="/buildbike"
                >
                    <div>
                        <Translate>BIKE_ADD_NEW</Translate>
                    </div>
                    <span className={styles.plus}>
                        +
                    </span>
                </MenuItemButton>
            </MenuItem>
        </NavBarDropdown>
    );
};

BikeRackDropdown.defaultProps = {
    onAuxClick: null,
    onClick: null,
    open: false,
};

BikeRackDropdown.propTypes = {
    onAuxClick: func,
    onClick: func,
    open: bool,
};

export default BikeRackDropdown;
